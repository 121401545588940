import React from 'react';

const Loading = ({ small = false, extraSmall = false }) => {
  let height = '80px';
  if (small) {
    height = '40px';
  }
  if (extraSmall) {
    height = '20px';
  }
  return <img src='/present/assets/images/loading-orange.svg' alt='loading' height={height} />;
};

export { Loading };
