import { ButtonHTMLAttributes } from 'react';
import styled, { CSSObject, DefaultTheme } from 'styled-components';
import { ButtonSize, ButtonStyle } from './Button.types';

const getBtnSize = (size: ButtonSize, theme: DefaultTheme): CSSObject => {
  switch (size) {
    case 'extraSmall':
      return {
        fontSize: 12,
        fontWeight: 700,
        height: 25,
        padding: theme.spacing(0, 1),
        lineHeight: 1,
      };
    case 'small':
      return { fontSize: 13, fontWeight: 500, height: 29, padding: theme.spacing(0, 1.5) };
    case 'medium':
      return { fontSize: 13, fontWeight: 500, height: 35, padding: theme.spacing(0, 1.75) };
    case 'large':
      return { fontSize: 14, fontWeight: 500, height: 40, padding: theme.spacing(0, 2.25) };
    default:
      return {};
  }
};

const getBtnStyles = (theme: DefaultTheme, btnstyle: ButtonStyle, size: ButtonSize): CSSObject => {
  const outlined = {
    backgroundColor: '#fff',
    color: theme.colors.gray[400],
    border: `1px solid ${theme.colors.gray[200]}`,
    '&:hover': {
      backgroundColor: theme.colors.gray[200],
    },
  };

  switch (btnstyle) {
    case 'primary':
      return {
        backgroundColor: theme.colors.primary.main,
        color: theme.colors.primary.contrastText,
        border: `1px solid ${theme.colors.primary.main}`,
        '&:hover': {
          backgroundColor: theme.colors.primary.dark,
        },
      };
    case 'submit':
      return {
        backgroundColor: theme.colors.secondary.main,
        color: theme.colors.secondary.contrastText,
        border: `1px solid ${theme.colors.secondary.main}`,
        '&:hover': {
          backgroundColor: theme.colors.secondary.dark,
        },
      };
    case 'option':
      return {
        ...outlined,
        boxShadow: size === 'large' ? '2px 4px 6px rgba(0, 0, 0, 0.07)' : 'none',
      };
    case 'back':
      return {
        ...outlined,
        color: theme.colors.gray[300],
        boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
      };
    case 'revisit':
      return {
        backgroundColor: theme.colors.gray[300],
        color: '#fff',
        border: `1px solid ${theme.colors.gray[300]}`,
        '&:hover': {
          backgroundColor: theme.colors.gray[400],
          borderColor: theme.colors.gray[400],
        },
      };
    case 'add':
      return {
        backgroundColor: theme.colors.gray[100],
        color: theme.colors.gray[400],
        border: `1px solid ${theme.colors.gray[100]}`,
        boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
        '&:hover': {
          borderColor: theme.colors.gray[200],
          backgroundColor: theme.colors.gray[200],
        },
      };
    case 'cancel':
      return {
        background: 'transparent',
        color: theme.colors.gray[300],
        border: 'none',
        '&:hover': {
          color: theme.colors.gray[400],
        },
      };
    case 'danger':
      return {
        background: '#ED0D21',
        color: '#fff',
        border: 'none',
        '&:hover': {
          backgroundColor: '#D0021B',
        },
      };
    default:
      return {};
  }
};

interface ButtonElProps extends ButtonHTMLAttributes<unknown> {
  btnstyle: ButtonStyle;
  size: ButtonSize;
  theme: DefaultTheme;
}

export const ButtonEl = styled('button')<ButtonElProps>(({ theme, btnstyle, size, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: 'max-content',
  transition: 'all .2s',
  borderRadius: 5,
  fontWeight: 500,
  outline: 'none',
  flexShrink: 0,
  ...getBtnStyles(theme, btnstyle, size),
  ...getBtnSize(size, theme),
  opacity: disabled ? 0.4 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
  cursor: disabled ? 'not-allowed' : 'pointer',
}));

export const Loader = styled('div')({
  width: '100%',
  height: '100%',
  backgroundImage: `url('/present/assets/images/loading-gray.svg')`,
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
});
