export const API_BASE_URL = process.env.REACT_APP_CP_API_URL; // eslint-disable-line
export const GOOGLE_MAP_KEY = 'AIzaSyCxm3nWEa7uo25jeDj0VVKxW8lm4XE30Xo';

export const MAX_SCALE_SELECTIONS = 18;

export const mapColorFromLetter = letter => {
  if (letter.toLowerCase() === 'a') {
    return ACTIVE_COLOR;
  }
  if (letter.toLowerCase() === 'p') {
    return PENDING_COLOR;
  }
  return CLOSED_COLOR;
};

export const mapColorFromStatus = status => {
  if (status === 'active') {
    return ACTIVE_COLOR;
  }
  if (status === 'pending') {
    return PENDING_COLOR;
  }
  if (status === 'sold') {
    return CLOSED_COLOR;
  }
};

export const ACTIVE_COLOR = '#7EBD39';
export const PENDING_COLOR = '#FF8D00';
export const CLOSED_COLOR = '#F13B3B';
