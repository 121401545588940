import React, { useMemo } from 'react';
import {
  InfoCard,
  InfoCardTypes,
} from '../../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import styles from '../CommonStyles.module.scss';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import classes from '../../../../view/pages/Authenticated/CompResults/CompsPerformance/PricingFavorability/PricingFavorability.module.scss';
import { MoneyFormatter } from '../../../../services/moneyFormatter';
import { usePricingFavorabilityData } from '../../../../../../../features/report/charts/pricing-favorability/usePricingFavorabilityData';

// This is for slide gauge
export const PricingFavorabilityGauge = ({ onClick = f => f }) => {
  const primaryColor = useUiConfigColorsPrimary();
  const { options, activeMonth } = usePricingFavorabilityData();

  const isDisabled = useMemo(
    () => options.every(option => !option.price && !option.sale_price),
    [options],
  );

  return (
    <InfoCard
      headline='Average Monthly List/Sale Price'
      type={InfoCardTypes.CUSTOM}
      content={_ => (
        <h2 style={{ color: primaryColor }} className={classes.priceSlide}>
          {MoneyFormatter.format(options[activeMonth]?.price)}
        </h2>
      )}
      description='Average list price of all monthly active and pending comparables'
      info='Bar graph that shows average list and sale price of all comparables'
      clicked={onClick}
      borderedCircleColor={primaryColor}
      className={styles.card}
      disabled={isDisabled}
    />
  );
};
