import * as types from './types';

export const setSelectedCategories = step => ({
  type: types.SELECTED_CATEGORIES_SET,
  payload: step,
});

export const setActivePropertyType = propertyType => ({
  type: types.ACTIVE_PROPERTY_TYPE_SET,
  payload: propertyType,
});

export const setHighlighted = properties => ({
  type: types.HIGHLIGHTED_SET,
  payload: properties,
});

export const setHighlightedOrder = order => ({
  type: types.HIGHLIGHTED_ORDER_SET,
  payload: order,
});

export const setCompViewMode = mode => ({
  type: types.COMP_VIEW_MODE,
  payload: mode,
});

export const setExcluded = properties => ({
  type: types.EXCLUDED_SET,
  payload: properties,
});

export const setExcludeInProgress = val => ({
  type: types.EXCLUDE_IN_PROGRESS,
  payload: val,
});

export const setThumbsUp = properties => ({
  type: types.THUMBS_UP_SET,
  payload: properties,
});

export const setPricePerSqftMode = isPricePerSqft => ({
  type: types.PRICE_PER_SQFT_MODE_SET,
  payload: isPricePerSqft,
});

export const setSelectedProperty = property => ({
  type: types.SELECTED_PROPERTY_SET,
  payload: property,
});

export const setCustomMarkers = markers => ({
  type: types.CUSTOM_MARKERS_SET,
  payload: markers,
});

export const setCustomEstimates = estimates => ({
  type: types.CUSTOM_ESTIMATES_SET,
  payload: estimates,
});

export const setIsPropertyModalOpen = isOpen => ({
  type: types.SET_IS_PROPERTY_MODAL_OPEN,
  payload: isOpen,
});

export const setFilteredProperties = data => ({
  type: types.SET_FILTERED_PROPERTIES,
  payload: data,
});

export const addNote = note => ({
  type: types.NOTES_ADD,
  payload: note,
});
