import { useEffect } from 'react';
import { useDomainWhitelabelSettings } from './useDomainWhitelabelSettings';
import { StandaloneAppDomains } from '../../services/DevelopmentUtilities/StandaloneAppDomains';
import { ROUTING_PREFIX } from '../../constants/routingPrefix';

export function useWhiteLabelAuthorizationGuard() {
  const { data: userSettings, isLoading, error } = useDomainWhitelabelSettings();

  useEffect(() => {
    if (StandaloneAppDomains.isStandaloneInstance() || !userSettings || error) return;

    if (!userSettings?.hasCorePresent) {
      window.location.href = '/';
      return;
    }

    if (!userSettings?.whiteLabelSettings && !userSettings.hasBoldTrail) {
      window.location.href = `${
        StandaloneAppDomains.get().kvc
      }${ROUTING_PREFIX}?noWhiteLabelSettings=true`;
      return;
    }

    if (!userSettings?.whiteLabelSettings && userSettings.hasBoldTrail) {
      window.location.href = `${
        StandaloneAppDomains.get().bt
      }${ROUTING_PREFIX}?noWhiteLabelSettings=true`;
      return;
    }
  }, [error, userSettings]);

  return { isLoading: isLoading && !StandaloneAppDomains.isStandaloneInstance() };
}
