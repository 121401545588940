import React from 'react';
import styled from 'styled-components';
import { useUiConfigFontsHeader } from '../../../../../../providers/providers/UiConfigFontProvider';
import { useScaleFactor } from '../../../../../PreviewSlide/ScaleFactorProvider';
import { CustomDrawingManagerControl } from './Control';

const Container = styled('div')({
  width: '450px',
  padding: '12.4px 6.4px 10.4px 6.4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  borderRadius: '5px 3.42585px 3.42585px 3.42585px',
  marginTop: '8px',
});

const ItemWrapper = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  margin: '0 6.2px',
  fontFamily: useUiConfigFontsHeader(),
}));

const Image = styled('img')({
  height: '100%',
  width: 'auto',
  marginRight: 5,
});

const TopControls: React.FC = (props, context) => {
  const position = (window as any).google.maps.ControlPosition.TOP_CENTER;
  const scaleFactor = useScaleFactor();

  const style: React.CSSProperties = {};

  if (scaleFactor) {
    style.transform = `scale(${scaleFactor})`;
    style.transformOrigin = 'auto 0';
    if (scaleFactor < 0.5) {
      style.marginTop = `-${8 * scaleFactor}px`;
    }
    if (scaleFactor < 0.16) {
      style.marginTop = `-${150 * scaleFactor}px`;
      style.width = '500px';
    }
  }
  return (
    <CustomDrawingManagerControl position={position}>
      <Container style={style}>
        <ItemWrapper>
          <Image src='/present/assets/images/map/pin-a.svg' /> ACTIVE COMPARABLE
        </ItemWrapper>
        <ItemWrapper>
          <Image src='/present/assets/images/map/pin-gray-a.svg' /> ACTIVE NON COMPARABLE
        </ItemWrapper>
      </Container>
    </CustomDrawingManagerControl>
  );
};

export default TopControls;
