import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import { FreshStale } from './FreshStale/FreshStale';
import { Competition } from './Competition/Competition';
import { SellingPace } from './SellingPace/SellingPace';
import { RateOfChange } from './RateOfChange/RateOfChange';
import { AverageDaysToSell } from './AverageDaysToSell/AverageDaysToSell';
import { PricingFavorability } from './PricingFavorability/PricingFavorability';
import { CurrentUserService } from '../../../../../../../../services/CurrentUserService';
import { withProperties } from '../../../../../../../../hoc/withProperties';

class CompsPerformanceComponent extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    graphs: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    graphs: {},
  };

  render() {
    const { className, graphs, properties } = this.props;

    return (
      <Wrapper>
        <Title>MARKET ASSESSMENT</Title>
        <Grid>
          <PricingFavorability data={graphs.pricing_favorability} />

          <Competition data={graphs.competition} />

          <SellingPace data={graphs.selling_pace} />

          {/* PricePerSqft */}
          {!CurrentUserService.isCanadaUser() && <FreshStale data={properties} />}

          <RateOfChange data={graphs.rate_of_change} />

          <AverageDaysToSell
            data={graphs.active_shelf_life ? graphs.active_shelf_life : graphs.avg_days_to_sell}
          />
        </Grid>
      </Wrapper>
    );
  }
}

const stateToProps = ({ report }) => ({
  graphs: report.reportDetailed.graphs,
});

export const CompsPerformance = withProperties(connect(stateToProps)(CompsPerformanceComponent));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.v2.gray[200]};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 28px;

  @media (max-width: 1340px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 790px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h3`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
