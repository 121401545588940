export const competitionPinConfigs = [
  { label: '0-2 Wks', image: '/present/assets/images/pins/color1.png' },
  { label: '2-4 Wks', image: '/present/assets/images/pins/color2.png' },
  { label: '4-6 Wks', image: '/present/assets/images/pins/color3.png' },
  { label: '6-8 Wks', image: '/present/assets/images/pins/color4.png' },
  { label: '8-10 Wks', image: '/present/assets/images/pins/color5.png' },
  { label: '10-12 Wks', image: '/present/assets/images/pins/color6.png' },
  { label: '12-14 Wks', image: '/present/assets/images/pins/color7.png' },
  { label: '14-16 Wks', image: '/present/assets/images/pins/color8.png' },
  { label: '16-18 Wks', image: '/present/assets/images/pins/color9.png' },
  { label: '18-20 Wks', image: '/present/assets/images/pins/color10.png' },
  { label: '20-22 Wks', image: '/present/assets/images/pins/color11.png' },
  { label: '22-24 Wks', image: '/present/assets/images/pins/color12.png' },
  { label: '24+ Wks', image: '/present/assets/images/pins/color13.png' },
];

export const getCompetitionPin = (daysOnMarket: number) => {
  for (let pin of competitionPinConfigs) {
    const from = parseInt(pin.label.split('-')[0]) * 7;
    const to = parseInt(pin.label.split('-')[1]) * 7;

    if (from < daysOnMarket && daysOnMarket <= to) {
      return pin.image;
    }
  }

  return '/present/assets/images/pins/color13.png';
};
