import { mapColorFromStatus } from '../../../../pages/PresentationCreate/dash/config/constants';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyStatus } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { CurrentUserService } from '../../../../services/CurrentUserService';
import { TProperty } from '../../../../types';
import { getPropertyId } from '../../services/getPropertyId';

interface Args {
  property: TProperty;
  subjectSize: number;
}

export const getInfoWindowContent = ({ property, subjectSize }: Args) => {
  const status = property.status;
  const s = getPropertyStatus(status);
  const adjustedPrice = property?.adjustedPrice
    ? MoneyFormatter.format(property.adjustedPrice)
    : null;

  const color = mapColorFromStatus(status);
  const clickEvent =
    property.partialMatch || property.excluded
      ? `document.dispatchEvent(new CustomEvent('add-comp', {detail: '${getPropertyId(
          property,
        )}'}))`
      : `document.dispatchEvent(new CustomEvent('remove-comp', {detail: '${getPropertyId(
          property,
        )}'}))`;
  let percentDiff: string | number = (property.size / subjectSize) * 100;
  if (percentDiff > 100) {
    percentDiff = Math.round(percentDiff - 100) + '% larger';
  } else {
    percentDiff = Math.round(100 - percentDiff) + '% smaller';
  }

  const infoWindowButtonStyles = `
  "text-decoration: none;
  text-align: center;
  display: inline-block;
  background: var(--color-primary);
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  text-transform: uppercase;"`;

  return `
    <div>
      <div style="color: ${color};font-weight: bold">
            <img style="max-height: 15px" src="/present/assets/images/map-pointer-${s}.png" alt="" />
            ${property.address.deliveryLine}
       </div>
      <hr>
      <div style= "margin-top: 5px;margin-bottom: 5px;font-weight: bold;">${
        status === 'sold'
          ? 'Sale price: ' + MoneyFormatter.format(property.salePrice)
          : 'List price: ' + MoneyFormatter.format(property.price)
      } <br>
      <div style="${adjustedPrice ? 'display: block;' : 'display: none;'}">
      ${status === 'sold' ? 'ADJ Sale Price: ' + adjustedPrice : 'ADJ List Price: ' + adjustedPrice}
      </div>
${
  status === 'sold'
    ? 'Sale date: ' + property.saleDateHuman
    : 'List date: ' + property.listDateHuman
} <br>
    ${property.beds} Beds <br />
    ${
      !CurrentUserService.isCanadaUser()
        ? NumberFormatter.format(property.size) + ' sqft (' + percentDiff + ')'
        : ''
    }
    </div>
     <button
        id="infoWindow${getPropertyId(property)}"
        style=${infoWindowButtonStyles}
        onclick="${clickEvent}"
      >${property.partialMatch || property.excluded ? 'Add to comps' : 'Remove from comps'}</button>
      <button
        id="infoWindow${getPropertyId(property)}"
        style=${infoWindowButtonStyles}
        onclick="document.dispatchEvent(new CustomEvent('more-info', {detail: {id: '${getPropertyId(
          property,
        )}', status: '${property.status}'}}))"
      >More Info</button>
  `;
};
