import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BorderedCircle } from '../../../../../components/BorderedCircle/BorderedCircle';
import { Card } from '../../../../../components/Card/Card';

import classes from './InfoCard.module.scss';
import { useTheme } from 'styled-components';

const InfoCardTypes = Object.freeze({
  CUSTOM: 'custom',
  CIRCLE: 'circle',
});

const getStyle = backgroundImage =>
  !backgroundImage
    ? {}
    : {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'right 5px top 50%',
      };

const getOneSheeterStyle = show => {
  return !show ? {} : { height: 213 };
};

const InfoCard = ({
  headline,
  clicked,
  type,
  content,
  description,
  sup,
  sub,
  background,
  backgroundLight,
  className,
  info,
  textContent = false,
  borderedCircleColor,
  disabled = false,
  oneSheeterSlide = false,
}) => {
  const [hovered, setHovered] = useState(false);
  const { colors } = useTheme();
  const primaryColor = borderedCircleColor || colors.v2.primary;

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Card
        // shadow
        hoverable={true}
        headline={headline}
        className={`${classes.infoCard} ${className} ${disabled && classes.disabled} ${
          oneSheeterSlide ? classes.oneSheeter : ''
        }`}
        style={{
          height: '100%',
          ...getStyle(hovered ? backgroundLight : background),
          ...getOneSheeterStyle(oneSheeterSlide),
        }}
        clicked={!disabled ? clicked : () => {}}
        infoColor={primaryColor}
        info={info}
        headerStyles={oneSheeterSlide ? { padding: '15px 0', fontSize: 16 } : {}}
      >
        {typeof content === 'function' &&
          (type === InfoCardTypes.CIRCLE ? (
            <BorderedCircle
              numberColor={primaryColor}
              boxSize='90px'
              borderSize='4px'
              sup={sup}
              sub={sub}
              style={
                oneSheeterSlide
                  ? { transform: 'scale(0.8) translate(20px, -20px)', transformOrigin: '0 0' }
                  : {}
              }
              hoveredColor={hovered ? colors.v2.gray[100] : '#fff'}
            >
              <div
                className={`${classes.content} 
              `}
              >
                {content()}
              </div>
            </BorderedCircle>
          ) : (
            content()
          ))}
        {description && (
          <p
            className={classes.description}
            style={oneSheeterSlide ? { transform: 'translateY(-40px)', fontSize: 12.5 } : {}}
          >
            {description}
          </p>
        )}
      </Card>
    </div>
  );
};

InfoCard.propTypes = {
  content: PropTypes.func,
  clicked: PropTypes.func,
  type: PropTypes.string,
  headline: PropTypes.string,
  sup: PropTypes.string,
  sub: PropTypes.string,
  background: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  info: PropTypes.string,
};

InfoCard.defaultProps = {
  content: f => f,
  clicked: f => f,
  type: InfoCardTypes.CIRCLE,
  headline: '',
  sup: '',
  sub: '',
  background: '',
  description: '',
  className: '',
  info: '',
};

export { InfoCard, InfoCardTypes };
