import { CustomMarker } from '../../../../components/Common/CustomMarker/CustomMarker.component';
import { TProperty } from '../../../../types';

interface Props {
  subject: TProperty;
}

export const SubjectMarker = ({ subject }: Props) => {
  return <CustomMarker icon={'/present/assets/images/map/pin-subject.svg'} property={subject} />;
};
