import React from 'react';
import { useTheme } from 'styled-components';

import { TProperty } from '../../../../../types';
import { CustomMarker } from '../../../../../components/Common/CustomMarker/CustomMarker.component';
import { svgStringToDataUrl } from '../../../../../components/Slide/services/svgStringToDataUrl';
import { getInfoWindowContent } from './getInfoWindowContent';

interface MarkersProps {
  properties: TProperty[];
  useNumberedMarkers?: boolean;
  onMoreInfo?: (property: TProperty) => void;
  isViewModeProperty?: boolean;
}

export const createSvgWithText = (text: string | number) => {
  return svgStringToDataUrl(`
  <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
    <g>
      <title>Layer 1</title>
      <path transform="rotate(-180 10 15)" stroke="#6dc510" id="svg_1" d="m6.58436,29.63005c-4.11828,-1.5886 -7.07015,-6.63633 -6.3993,-10.94298c0.42301,-2.71566 9.34744,-19.20163 10.12684,-18.70716c0.25437,0.16141 2.51804,4.00496 5.03039,8.54127c4.0245,7.26669 4.56787,8.6217 4.56787,11.39075c0,5.61273 -4.3757,10.21021 -9.62963,10.11768c-1.53317,-0.027 -3.19646,-0.20681 -3.69617,-0.39957l0,0zm4.12605,-2.82299c0.13632,-0.72665 -0.1821,-1.08103 -0.97136,-1.08103c-1.79879,0 -5.72251,-2.33877 -4.85148,-4.54727c0.87102,-2.20851 -2.1714,-2.63606 -2.40429,-0.42723c-0.44869,4.25561 7.47121,10.08502 8.22713,6.05554l0,0z" fill="#6dc510"/>
      <ellipse stroke="#6dc510" ry="5.70281" rx="5.22088" id="svg_8" cy="7.18273" cx="12.51004" fill="#6dc510"/>
      <ellipse id="svg_9" cy="27.98594" cx="16.28514" stroke="#6dc510" fill="#6dc510"/>
      <text transform="matrix(1 0 0 0.951011 0 0.139312)" stroke="#6dc510" font-style="normal" font-weight="normal" xml:space="preserve" text-anchor="start" font-family="Figtree, sans-serif" font-size="16" stroke-width="0" id="svg_10" y="17.69318" x="5.42567" fill="#ffffff">${text}</text>
    </g>
  </svg>
`);
};

export const Markers: React.FC<MarkersProps> = ({
  properties,
  useNumberedMarkers,
  onMoreInfo,
  isViewModeProperty,
}) => {
  const theme = useTheme();

  const markers = React.useMemo(
    () => (useNumberedMarkers ? properties.map((p, i) => createSvgWithText(i + 1)) : []),
    [properties, useNumberedMarkers],
  );

  return (
    <>
      {properties.map((property, i) => (
        <CustomMarker
          key={property.id}
          property={property}
          icon={useNumberedMarkers ? markers[i] : '/present/assets/images/map/pin-a.svg'}
          infoWindowContent={getInfoWindowContent({
            property,
            theme,
            hasOnMoreInfo: Boolean(onMoreInfo),
            isViewModeProperty,
          })}
          isClickable
        />
      ))}
    </>
  );
};
