import React from 'react';

import { TPresentationType } from '../../types';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import { WTRCreator } from '../../features/WinTheRepresentation';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import { BuyerTourPresentationCreator } from '../../features/BuyerTour';
import { usePresentationCreationQuery } from '../../hooks/usePresentationCreationQuery';
import { PresentInfoCreator } from '../../features/PresentInfo/create/PresentInfoCreator';

const builderComponentByTypeMap: Partial<Record<TPresentationType, React.FC>> = {
  [PRESENTATION_TYPES.PRESENT_INFO]: PresentInfoCreator,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: WTRCreator,
  [PRESENTATION_TYPES.BUYER_TOUR]: BuyerTourPresentationCreator,
};

export const PresentationCreator: React.FC = withLoadedUser(() => {
  const { type } = usePresentationCreationQuery();

  const BuilderComponent = builderComponentByTypeMap[type];

  return !BuilderComponent ? null : <BuilderComponent />;
});
