import { useMemo } from 'react';
import { TPropertyRowPdf } from '../../../../types';
import styled from 'styled-components';

interface Props {
  data?: TPropertyRowPdf;
}

export const DetailsRow = ({ data }: Props) => {
  const listingAdjustedPrice = useMemo(() => {
    if (data?.status === 'c') return null;

    return data?.adjustedPrice;
  }, [data?.adjustedPrice, data?.status]);

  const saleAdjustedPrice = useMemo(() => {
    if (data?.status !== 'c') return null;

    return data?.adjustedPrice;
  }, [data?.adjustedPrice, data?.status]);

  if (!data) return null;

  return (
    <Container>
      {data?.address && (
        <Address>
          {data?.address && data?.address}
          {data.highlighted ? (
            <IconsWrapper>
              <Icon src={`/present/assets/images/star-${data.status}.png`} alt='star' />
            </IconsWrapper>
          ) : (
            ''
          )}
        </Address>
      )}
      {data?.label && <Label>{data.label}</Label>}
      <PriceContainer>
        <Price>{data.price}</Price>
        {listingAdjustedPrice && (
          <AdjPrice>
            {listingAdjustedPrice} <AdjSuffix>ADJ</AdjSuffix>
          </AdjPrice>
        )}
      </PriceContainer>
      {data.salePrice && (
        <PriceContainer>
          <Price>{data.salePrice}</Price>
          {saleAdjustedPrice && (
            <AdjPrice>
              {saleAdjustedPrice} <AdjSuffix>ADJ</AdjSuffix>
            </AdjPrice>
          )}
        </PriceContainer>
      )}
      <RoomCount>{data.bd}</RoomCount>
      <RoomCount>{data.bth}</RoomCount>
      <PropertySize>{data.sqft}</PropertySize>
      <PricePerSqft>{data.pricePerSq}</PricePerSqft>
      <Built>{data.built}</Built>
      <Date>{data.listDate && data.listDate}</Date>
      {data.status !== 'p' && <CDom>{data.cdom}</CDom>}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 14px 0;
  text-align: left;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray[400]};
  align-items: center;
`;

const Address = styled.div`
  min-width: 250px;
  max-width: 250px;
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  min-width: 250px;
  max-width: 250px;
  font-size: 13px;
  text-align: right;
  padding-right: 30px;
`;

const IconsWrapper = styled.div`
  margin-left: 3px;
  position: relative;
  display: inline-block;
`;

const Icon = styled.img`
  height: 12px;
`;

const PriceContainer = styled.div`
  min-width: 100px;
  max-width: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Price = styled.div`
  color: ${({ theme }) => theme.colors.gray[400]};
`;

const AdjPrice = styled.div`
  color: #b1b1b1;
`;

const AdjSuffix = styled.span`
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
`;

const CDom = styled.div`
  text-align: center;
  margin: 0px auto;
`;

const RoomCount = styled.div`
  width: 35px;
  min-width: 35px;
  max-width: 35px;
`;

const Date = styled.div`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
`;

const Built = styled.div`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
`;

const PropertySize = styled.div`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
`;

const PricePerSqft = styled.div`
  width: 90px;
  min-width: 90px;
  max-width: 90px;
`;
