import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface IconP {
  status: 'a' | 'c' | 'p' | 'coming_soon';
  size: number;
  text: string | number;
}

export const StarIcon: React.FC<IconP> = ({ status, size = 25, text }) => {
  return (
    <IconContainer style={{ position: 'relative' }}>
      <div style={{ position: 'relative', marginRight: 4 }}>
        <img
          src={`/present/assets/images/star-${status}.png`}
          alt='star'
          style={{ width: size, height: 'auto' }}
        />
        <span
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: size * 0.37,
            color: '#fff',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {status === 'coming_soon' ? 'CS' : status.toUpperCase()}
        </span>
      </div>
      <span style={{ fontSize: size * 0.65 }}>{text}</span>
    </IconContainer>
  );
};
