import styled from 'styled-components';

import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyStatus } from '../../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { limitLongString } from '../../../../../services/limitLongString';
import { StatusShort, THighlightedProperty } from '../../../../../types';
import { LabelAndAddressWithPrice } from './LabelAndAddressWithPrice';
import { useColorBasedOnStatus } from '../../../../../hooks/useColorBasedOnStatus';

interface RangeMarkerProps {
  id?: string;
  text?: string;
  height?: number;
  offsetLeft?: string;
  price?: number;
  priceFrom?: string;
  priceTo?: string;
  property: THighlightedProperty | null;
  onClick?: () => void;
  agentInfo?: any;
  isLastMarker?: boolean;
  isPdf?: boolean;
  adjustedPrice?: number;
  overridePlotBy?: boolean;
}

export const RangeMarker = ({
  id = '',
  text = '',
  height = 40,
  offsetLeft = '0',
  price = 0,
  priceFrom = '',
  priceTo = '',
  property = null,
  onClick,
  agentInfo = null,
  isLastMarker = false,
  isPdf = false,
  adjustedPrice,
  overridePlotBy,
}: RangeMarkerProps) => {
  const user = useUser();

  let avatar = user ? user.image : null;
  let agentName = user ? user.name : '';
  const defaultPhoto = '/present/assets/images/sideBar/user.png';

  if (agentInfo) {
    avatar = agentInfo.avatar ? agentInfo.avatar : null;
    agentName = `${agentInfo.first_name} ${agentInfo.last_name}`;
  }

  const propertySize = property?.size ? `${NumberFormatter.format(property.size)} sqft` : '';
  const isReduced = parseInt(offsetLeft) > 88;

  const status = getPropertyStatus(property?.status) as StatusShort;
  const getColorBasedOnStatus = useColorBasedOnStatus();
  const statusColor = getColorBasedOnStatus(status);

  return (
    <RangeMarkerWrapper onClick={onClick} style={{ height: `${height}px`, left: `${offsetLeft}` }}>
      <Tooltip isReduced={isReduced} isPdf={isPdf}>
        <IconContainer>
          {id ? (
            <>
              <IconsWrapper isPdf={isPdf}>
                <StarImage
                  src={`/present/assets/images/star-${getPropertyStatus(property?.status)}.png`}
                  alt='star'
                />
                <span>{id === 'coming_soon' ? 'cs' : id}</span>
              </IconsWrapper>
              {property?.label && <Label color={statusColor}>{property?.label}</Label>}
            </>
          ) : (
            <AvatarImage
              style={{
                backgroundImage: `url(${avatar || defaultPhoto})`,
              }}
            />
          )}
        </IconContainer>
        <div>
          {!id ? null : (
            <LabelAndAddressWithPrice
              text={text}
              property={property}
              price={price}
              isLastMarker={isLastMarker}
              slideMode={isPdf ? 'pdf' : 'web'}
              adjustedPrice={adjustedPrice}
              overridePlotBy={overridePlotBy}
              isPdf={isPdf}
            />
          )}

          {!id && <AgentName isPdf={isPdf}>{agentName}</AgentName>}
          <p style={{ textTransform: 'lowercase' }}>
            {priceFrom && priceTo
              ? `${MoneyFormatter.format(priceFrom)} - ${MoneyFormatter.format(priceTo)}`
              : property &&
                  !isNaN(property.beds) &&
                  !isNaN(property.size) &&
                  !isNaN(property.distance)
                ? limitLongString(
                    `${property.beds} bed | ${propertySize} | ${property.distance}mi away`,
                    isLastMarker ? 29 : 100,
                  )
                : price
                  ? MoneyFormatter.format(price)
                  : ''}
          </p>
        </div>
      </Tooltip>
      <Dot />
    </RangeMarkerWrapper>
  );
};

const RangeMarkerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  border-right: 1px solid #ddd;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 31px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
`;

const StarImage = styled.img`
  width: 20px;
`;

const Label = styled.div<{ color: string }>`
  font-size: 7px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: wrap;
  color: ${({ color }) => color};
`;

const Tooltip = styled.div<{ isReduced?: boolean; isPdf?: boolean }>`
  min-width: 100px;
  background-color: #fff;
  color: #000;
  font-size: ${({ isPdf }) => (isPdf ? '12px' : '11px')};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  white-space: nowrap;
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;

  & p {
    font-size: 10px;
    text-align: left;
    color: #808080;
    font-weight: normal;
    margin-top: 2px;
  }

  &:hover {
    background: #f4f4f4;
    border-color: #6ab4e4;
    &:after {
      border-right-color: #6ab4e4;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: -11px;
    margin-top: -5px;
    border: 5px solid transparent;
    border-right-color: #ddd;
    rotate: 180deg;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: -10px; /* To the right of the tooltip */
    margin-top: -5px;
    border: 5px solid transparent;
    border-right-color: #fff;
    rotate: 180deg;
  }

  ${({ isReduced, isPdf }) =>
    isReduced &&
    isPdf &&
    `max-width: 215px;
    div {
        max-width: 170px;
    }
    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
        overflow: hidden;
        display: block;
    }`}
`;

const IconsWrapper = styled.div<{ isPdf?: boolean }>`
  position: relative;
  height: 20px;
  width: 20px;

  & img {
    width: 20px;
  }

  & span {
    position: absolute;
    top: ${({ isPdf }) => (isPdf ? '50%' : '47%')};
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 8px;
    font-weight: 700;
  }
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ddd;
  position: absolute;
  right: -3px;
  bottom: 0;
`;

const AgentName = styled.p<{ isPdf?: boolean }>`
  color: #000;
  font-size: ${({ isPdf }) => (isPdf ? '12px' : '14px')};
  font-weight: 800;
`;

const AvatarImage = styled.div`
  margin-right: 5px;
  background-size: cover;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;
