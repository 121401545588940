import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserSettings } from '../../types';
import { corePresentApi } from '../../api/CorePresentApi';
import { StandaloneAppDomains } from '../../services/DevelopmentUtilities/StandaloneAppDomains';

const mockData = {
  hasCorePresent: true,
  hasBoldTrail: true,
  // whiteLabelSettings: null,
  whiteLabelSettings: {
    primary_color: '#d6a435',
    secondary_color: '#ea0b0b',
    login_logo: '',
    large_logo: '',
    small_logo: '',
    favicon: '',
    login_background: '',
    package_level: 'standard',
  },
};

const fetchUserSettings = async (domain?: string | null): Promise<UserSettings | undefined> => {
  return await corePresentApi.get(`/accountDetail/whitelabelSettings?domain=${domain}`);
};

export function useDomainWhitelabelSettings(): UseQueryResult<UserSettings> {
  const domain = window.location.hostname;
  const isStandaloneApp = StandaloneAppDomains.isStandaloneInstance();

  return useQuery(['userSettings', domain], () => fetchUserSettings(domain), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: Boolean(domain) && !isStandaloneApp,
    retry: 1,
  });
}
