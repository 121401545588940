import { useState } from 'react';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { customMarkersAtom } from '../../../state/customMarkersAtom';
import { SinglePrice } from './SinglePrice';
import { RangePrice } from './RangePrice';
import { AddButton } from './AddButton';
import { useDispatch } from 'react-redux';
import { resultOperations } from '../../../../../pages/PresentationCreate/dash/store/result';
import { subjectAtom } from '../../../state/subjectAtom';

export type TRangePrice = {
  from?: number | null;
  to?: number | null;
};

export const AgentPrice = () => {
  const [singlePrice, setSinglePrice] = useState<number | null | undefined>(null);
  const [rangePrice, setRangePrice] = useState<TRangePrice>({ from: null, to: null });

  const [customMarkers] = useAtom(customMarkersAtom);
  const [subject] = useAtom(subjectAtom);
  const dispatch = useDispatch();

  const user = useUser();

  const isActivated = !!customMarkers?.length;

  const resetAgentPrice = () => {
    setSinglePrice(null);
    setRangePrice({ from: null, to: null });
    dispatch(resultOperations.setCustomMarkers([]));
  };

  const onToggle = () => {
    if (isActivated) {
      resetAgentPrice();
      return;
    }
    const priceToSet = singlePrice
      ? singlePrice
      : ((rangePrice?.from || 0) + (rangePrice.to || 0)) / 2;

    dispatch(
      resultOperations.setCustomMarkers([
        {
          name: user?.name,
          price: priceToSet || 0,
          pricePerSqft: Math.round(priceToSet / subject.property.size),
          priceFrom: rangePrice?.from,
          priceTo: rangePrice?.to,
        },
      ]),
    );
  };

  return (
    <Container>
      <AgentPriceText>AGENT PRICE:</AgentPriceText>

      <AgentPriceWrapper>
        {!isActivated && (
          <>
            <SinglePrice price={singlePrice} onChange={setSinglePrice} isActivated={isActivated} />
            <ORText>OR</ORText>
            <RangePrice price={rangePrice} onChange={setRangePrice} isActivated={isActivated} />
          </>
        )}
        <AddButton isActivated={isActivated} onClick={onToggle} />
      </AgentPriceWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  font-family: Figtree;
  flex-direction: column;
`;

const AgentPriceText = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[400]};
  margin-bottom: 10px;
`;

const AgentPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  gap: 10px;

  @media all and (max-width: 814px) {
    flex-direction: column;
  }
`;

const ORText = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[400]};
`;
