import { useAtom, useAtomValue } from 'jotai';
import { useMaxPropertiesLimitToast } from '../../hooks/useMaxPropertiesLimitToast';

import { flagsAtom } from '../../state/flagsAtom';
import {
  ReportCompAverages,
  ReportEstimates,
  ReportFlagValues,
  TCompConfigItem,
  TReportSelectedCategory,
} from '../../../../types';
import { selectedCategoriesAtom } from '../../state/selectedCategoriesAtom';
import { useDispatch } from 'react-redux';
import { resultOperations } from '../../../../pages/PresentationCreate/dash/store/result';
import { pricePerSqftModeAtom } from '../../state/pricePerSqftModeAtom';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { useMemo } from 'react';
import { extractPropertyGroups } from '../../services/extractPropertiesGroups';
import { subjectAtom } from '../../state/subjectAtom';
import { useCategories } from '../hooks/useCategories';
import styled from 'styled-components';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { Checkbox } from '../../components/Checkbox';

interface Props {
  field: TCompConfigItem;
}

// TODO - move actions from redux to jotai

export const CategoryCheckbox = ({ field }: Props) => {
  const dispatch = useDispatch();
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const isPricePerSqftMode = useAtomValue(pricePerSqftModeAtom);

  const flags = useAtomValue(flagsAtom);
  const categoriesValues = useCategories();

  const [selected, setSelected] = useAtom(selectedCategoriesAtom);

  const properties = useAtomValue(reportPropertiesAtom);
  const subject = useAtomValue(subjectAtom);

  const propertyGroups = useMemo(() => {
    return extractPropertyGroups(properties);
  }, [properties]);

  const { maxPropertiesLimitToast, canAddMoreItemsToScale } = useMaxPropertiesLimitToast();

  const click = (item: TCompConfigItem) => {
    const selectedCopy: TReportSelectedCategory[] = selected.map(item => ({ ...item }));

    const positionInList = selectedCopy.findIndex(category => category.id === item.id);

    if (positionInList !== -1) {
      selectedCopy.splice(positionInList, 1);
      if (!isPresentationView) {
        dispatch(resultOperations.setSelectedCategories(selectedCopy));
        return;
      }

      setSelected(selectedCopy);
      return;
    }

    if (!canAddMoreItemsToScale) {
      maxPropertiesLimitToast();
      return;
    }

    selectedCopy.push({
      ...item,
      name: item.labelHtml,
      value: categoriesValues[item.id],
      valuePerSqft:
        categoriesValues[
          (item.id + '_per_sqft') as
            | keyof ReportCompAverages
            | keyof ReportEstimates
            | keyof ReportFlagValues
        ],
    });

    const valuePropertyName = isPricePerSqftMode ? 'valuePerSqft' : 'value';

    selectedCopy.sort((a, b) => (a?.[valuePropertyName] || 0) - (b?.[valuePropertyName] || 0));

    if (!isPresentationView) {
      dispatch(resultOperations.setSelectedCategories(selectedCopy));
      return;
    }

    setSelected(selectedCopy);
  };

  const checked = selected.find(item => item.id === field.id);
  const value = isPricePerSqftMode ? categoriesValues[field.idPerSqft] : categoriesValues[field.id];

  let theLabel = (field && field.label) || '';

  if (field && field?.customizedData?.getCount) {
    theLabel = `${theLabel} (${field.customizedData.getCount(propertyGroups, subject.property)})`;
  }

  if (
    field &&
    field?.customizedData?.getFlaggedCount &&
    field?.customizedData?.source === 'flags' &&
    flags
  ) {
    theLabel = `${theLabel} (${field.customizedData.getFlaggedCount(flags)})`;
  }

  return (
    <Wrapper style={isPresentationView ? {} : { fontFamily: 'Figtree' }}>
      <Checkbox
        label={theLabel}
        onChange={() => click(field)}
        value={!!checked}
        disabled={!value}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
