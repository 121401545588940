import {
  PROPERTY_STATUS,
  PropertyGroups,
  ReportFlagsResults,
  TCompConfigItem,
} from '../../../../types';

const getFlagsCount = (flags: ReportFlagsResults, prop: keyof ReportFlagsResults) => {
  if (!flags[prop]) return 0;
  return flags[prop].filter(p => !p.excluded).length;
};

const mapProperties = (flags: ReportFlagsResults, prop: keyof ReportFlagsResults) => {
  const result: PropertyGroups = {
    active: null,
    pending: null,
    sold: null,
    comingSoon: null,
  };

  if (!flags[prop]) return result;

  flags[prop].forEach(p => {
    if (!p.excluded) {
      if (!result[p.status as PROPERTY_STATUS]) result[p.status as PROPERTY_STATUS] = [];
      result[p.status as PROPERTY_STATUS]?.push(p);
    }
  });

  return result;
};

export enum FOOTAGE_KEYS {
  FROM_25_TO_50 = 'FROM_25_TO_50',
  FROM_50_TO_75 = 'FROM_50_TO_75',
  FROM_75_TO_100 = 'FROM_75_TO_100',
}

export enum LARGER_FOOTAGE_KEYS {
  OVER_100 = 'OVER_100',
}

export enum BEDROOM_KEYS {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
}

export const LARGER_FLAGS_FOOTAGE: Record<FOOTAGE_KEYS | LARGER_FOOTAGE_KEYS, TCompConfigItem> =
  Object.freeze({
    [FOOTAGE_KEYS.FROM_25_TO_50]: {
      label: '25-50% Larger',
      labelHtml: '25-50% <span>LARGER </span> comps',
      id: 'size_one',
      idPerSqft: 'size_one_per_sqft',
      icon: '/present/assets/images/dashboard/categories/flags2.png',
      customizedData: {
        source: 'flags',
        getFlagsProperties: flags => mapProperties(flags, 'size_one_results'),
        getFlaggedCount: flags => getFlagsCount(flags, 'size_one_results'),
      },
    },
    [FOOTAGE_KEYS.FROM_50_TO_75]: {
      label: '50-75% Larger',
      labelHtml: '50-75% <span>LARGER </span> comps',
      id: 'size_two',
      idPerSqft: 'size_two_per_sqft',
      icon: '/present/assets/images/dashboard/categories/flags2.png',
      customizedData: {
        source: 'flags',
        getFlagsProperties: flags => mapProperties(flags, 'size_two_results'),
        getFlaggedCount: flags => getFlagsCount(flags, 'size_two_results'),
      },
    },
    [FOOTAGE_KEYS.FROM_75_TO_100]: {
      label: '75-100% Larger',
      labelHtml: '75-100% <span>LARGER </span> comps',
      id: 'size_three',
      idPerSqft: 'size_three_per_sqft',
      icon: '/present/assets/images/dashboard/categories/flags2.png',
      customizedData: {
        source: 'flags',
        getFlagsProperties: flags => mapProperties(flags, 'size_three_results'),
        getFlaggedCount: flags => getFlagsCount(flags, 'size_three_results'),
      },
    },
    [LARGER_FOOTAGE_KEYS.OVER_100]: {
      label: '100%+ Larger',
      labelHtml: '100%+ <span>LARGER </span> comps',
      id: 'size_four',
      idPerSqft: 'size_four_per_sqft',
      icon: '/present/assets/images/dashboard/categories/flags2.png',
      customizedData: {
        source: 'flags',
        getFlagsProperties: flags => mapProperties(flags, 'size_four_results'),
        getFlaggedCount: flags => getFlagsCount(flags, 'size_four_results'),
      },
    },
  });

export const LARGER_FLAGS_BEDROOMS: Record<BEDROOM_KEYS, TCompConfigItem> = Object.freeze({
  [BEDROOM_KEYS.ONE]: {
    label: '+1 Beds',
    labelHtml: '+1 <span>BEDS </span> comps',
    id: 'bed_one',
    idPerSqft: 'bed_one_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_one_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_one_results'),
    },
  },
  [BEDROOM_KEYS.TWO]: {
    label: '+2 Beds',
    labelHtml: '+2 <span>BEDS </span> comps',
    id: 'bed_two',
    idPerSqft: 'bed_two_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_two_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_two_results'),
    },
  },
  [BEDROOM_KEYS.THREE]: {
    label: '+3 or more Beds',
    labelHtml: '+3 or more <span>BEDROOMS </span> comps',
    id: 'bed_three',
    idPerSqft: 'bed_three_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_three_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_three_results'),
    },
  },
});

export const SMALLER_FLAGS_FOOTAGE: Record<FOOTAGE_KEYS, TCompConfigItem> = Object.freeze({
  [FOOTAGE_KEYS.FROM_25_TO_50]: {
    label: '25-50% Smaller',
    labelHtml: '25-50% <span>SMALLER </span> comps',
    id: 'size_one_negative',
    idPerSqft: 'size_one_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'size_one_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'size_one_negative_results'),
    },
  },
  [FOOTAGE_KEYS.FROM_50_TO_75]: {
    label: '50-75% Smaller',
    labelHtml: '50-75% <span>SMALLER </span> comps',
    id: 'size_two_negative',
    idPerSqft: 'size_two_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'size_two_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'size_two_negative_results'),
    },
  },
  [FOOTAGE_KEYS.FROM_75_TO_100]: {
    label: '75-100% Smaller',
    labelHtml: '75-100% <span>SMALLER </span> comps',
    id: 'size_three_negative',
    idPerSqft: 'size_three_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'size_three_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'size_three_negative_results'),
    },
  },
});

export const SMALLER_FLAGS_BEDROOMS: Record<BEDROOM_KEYS, TCompConfigItem> = Object.freeze({
  [BEDROOM_KEYS.ONE]: {
    label: '-1 Bed',
    labelHtml: '-1 <span>BEDROOMS </span> comps',
    id: 'bed_one_negative',
    idPerSqft: 'bed_one_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_one_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_one_negative_results'),
    },
  },
  [BEDROOM_KEYS.TWO]: {
    label: '-2 Beds',
    labelHtml: '-2 <span>BEDROOMS </span> comps',
    id: 'bed_two_negative',
    idPerSqft: 'bed_two_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_two_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_two_negative_results'),
    },
  },
  [BEDROOM_KEYS.THREE]: {
    label: '-3 or more Beds',
    labelHtml: '-3 or more <span>BEDROOMS </span> comps',
    id: 'bed_three_negative',
    idPerSqft: 'bed_three_negative_per_sqft',
    icon: '/present/assets/images/dashboard/categories/flags2.png',
    customizedData: {
      source: 'flags',
      getFlagsProperties: flags => mapProperties(flags, 'bed_three_negative_results'),
      getFlaggedCount: flags => getFlagsCount(flags, 'bed_three_negative_results'),
    },
  },
});
