import { CustomMarker } from '../../../../components/Common/CustomMarker/CustomMarker.component';
import { getPropertyStatus } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { TProperty } from '../../../../types';
import { getInfoWindowContent } from './getInfoWindowContent';

interface Props {
  property: TProperty;
  subject: TProperty;
}

export const PropertyMarker = ({ property, subject }: Props) => {
  const pinName = getPropertyStatus(property.status); //move to services

  const icon =
    property.partialMatch || property.excluded
      ? `/present/assets/images/map/pin-gray-${pinName}.svg?v=1`
      : `/present/assets/images/map/pin-${pinName}.svg?v=1`;

  return (
    <CustomMarker
      key={property?.id}
      icon={icon}
      property={property}
      isClickable
      infoWindowContent={getInfoWindowContent({ property, subjectSize: subject?.size })}
    />
  );
};
