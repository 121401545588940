import { v4 as v4uuid } from 'uuid';

import { corePresentApi } from './CorePresentApi';
import { TSection, TSlide, TSlideId, TUserConfigs } from '../types';
import { KvCoreEntityAdapter } from './adapters/KvCoreEntityAdapter';
import { CustomizationSettingsApi } from '../pages/PresentationSettings/api/CustomizationSettingsApi';
import { SlidesConfigOrderAdapter } from '../services/SlidesConfigOrderAdapter';
import { pushSlideToTheEndOfTheSlidesOrder } from '../services/pushSlideToTheEndOfTheSlideOrder';

export type TDynamicSlideDto = {
  section: TSection;
  slide: TSlide;
};

const ENDPOINT = `/custom-slides`;

const addDynamicSlideToSectionSlides = (
  sectionsSlide: Record<string, Partial<TSlide>[]>,
  section: TSection,
  newDynamicSlide: TDynamicSlideDto,
) => {
  const updatedSectionsSlide = { ...sectionsSlide };
  Object.keys(updatedSectionsSlide).forEach(sectionId => {
    if (section === sectionId) {
      updatedSectionsSlide[sectionId] = [
        ...updatedSectionsSlide[sectionId],
        {
          id: newDynamicSlide?.slide?.id,
          includeSlide: newDynamicSlide?.slide?.includeSlide,
        },
      ];
      if (sectionId === 'closingAndNextSteps') {
        const sectionSlides = [...updatedSectionsSlide['closingAndNextSteps']];
        updatedSectionsSlide['closingAndNextSteps'] = getValidOrderedSlides(
          sectionSlides,
          'closingAndNextSteps',
        ) as Partial<TSlide>[];
      }
      if (sectionId === 'startingSlides') {
        const sectionSlides = [...updatedSectionsSlide['startingSlides']];
        updatedSectionsSlide['startingSlides'] = getValidOrderedSlides(
          sectionSlides,
          'coverSlide',
        ) as Partial<TSlide>[];
      }
    }
  });

  return updatedSectionsSlide;
};

const removeDynamicSlideFromSectionSlides = (
  sectionsSlide: Record<string, Partial<TSlide>[]>,
  slideId: string,
) => {
  const updatedSectionSlides = { ...sectionsSlide };
  Object.keys(updatedSectionSlides).forEach(sectionId => {
    updatedSectionSlides[sectionId] = updatedSectionSlides[sectionId].filter(s => s.id !== slideId);
  });

  return updatedSectionSlides;
};

const mapGroupApiToEntities = (group: any) => {
  return (
    group?.data?.map((dynamicSlideConfig: any) => ({
      ...dynamicSlideConfig,
      slide: {
        ...dynamicSlideConfig.slide,
        isEditable: !!group.can_be_edited_by_user,
        entityId: group.kvcore_entity_id,
      },
    })) ?? []
  );
};

const mapApiToEntity = (response: any) => {
  let dynamicSlides: any[] = [];

  response?.forEach((group: any) => {
    const adaptedSlides = mapGroupApiToEntities(group);
    dynamicSlides = [...dynamicSlides, ...adaptedSlides];
  });

  return dynamicSlides;
};

const mapEntityToApi = (entity: TDynamicSlideDto) => ({
  section: entity.section,
  slide: entity.slide,
});

const mapEntitiesToApi = (entities: TDynamicSlideDto[]) => {
  return entities?.map(mapEntityToApi) ?? [];
};

const removeDuplicates = (dynamicSlides: TDynamicSlideDto[]): TDynamicSlideDto[] => {
  if (!dynamicSlides?.length) return [];

  const dSlideMap = dynamicSlides.reduce(
    (map, dSlide) => ({ ...map, [dSlide.slide.id]: dSlide }),
    {},
  );

  return Object.values(dSlideMap);
};

const getValidOrderedSlides = (sectionSlidesList: Partial<TSlide>[], slideId: TSlideId) => {
  const sectionSlides = sectionSlidesList.map(slide => slide.id as TSlideId);
  const validOrderedSlides = pushSlideToTheEndOfTheSlidesOrder(sectionSlides, slideId);
  return validOrderedSlides
    .map(slideId => sectionSlidesList.find(slide => slide.id === slideId))
    .filter(slide => !!slide);
};

export class DynamicSlidesApi {
  static async create(
    section: TSection,
    slide: TSlide,
    hash: string,
    entityId: number | null,
    config?: TUserConfigs,
    uuid?: string,
  ): Promise<{ slides: TDynamicSlideDto[]; config?: TUserConfigs }> {
    const newDynamicSlide: TDynamicSlideDto = {
      section,
      slide: {
        ...slide,
        includeSlide: false,
        id: uuid ?? v4uuid(),
        isEditable: true,
        entityId,
      },
    };

    const dynmc = await DynamicSlidesApi.fetchAllOfEntityType(hash, entityId);

    await corePresentApi.post(ENDPOINT, {
      data: JSON.stringify([...mapEntitiesToApi(dynmc), mapEntityToApi(newDynamicSlide)]),
      owner_type: KvCoreEntityAdapter.toOwner(entityId),
      kvcore_entity_id: entityId,
    });

    if (config) {
      const updatedConfig = { ...config };

      Object.keys(updatedConfig).forEach(key => {
        const category = updatedConfig[key as keyof TUserConfigs];

        if (category.slides) {
          const { sections, orderById } = category.slides;
          orderById.forEach(sectionId => {
            if (section === sectionId) {
              sections[sectionId].slides = [...sections[sectionId].slides, newDynamicSlide.slide];
            }
          });
          category.order = SlidesConfigOrderAdapter.toNewConfig(category.slides);

          delete category.slides;
        }

        if (category?.order?.sectionsSlide) {
          category.order.sectionsSlide = addDynamicSlideToSectionSlides(
            category.order.sectionsSlide,
            section,
            newDynamicSlide,
          );
        }

        if (category?.orderByType?.winTheListing) {
          category.orderByType.winTheListing.sectionsSlide = addDynamicSlideToSectionSlides(
            category.orderByType.winTheListing.sectionsSlide,
            section,
            newDynamicSlide,
          );
        }
        if (category?.orderByType?.winTheOffer) {
          category.orderByType.winTheOffer.sectionsSlide = addDynamicSlideToSectionSlides(
            category.orderByType.winTheOffer.sectionsSlide,
            section,
            newDynamicSlide,
          );
        }
        if (category?.orderByType?.presentInfo) {
          category.orderByType.presentInfo.sectionsSlide = addDynamicSlideToSectionSlides(
            category.orderByType.presentInfo.sectionsSlide,
            section,
            newDynamicSlide,
          );
        }
      });

      await CustomizationSettingsApi.update({
        entityId,
        config: updatedConfig,
      });
    }
    const slides = (await DynamicSlidesApi.fetchAll(hash)) ?? [];
    const fullConfig = await CustomizationSettingsApi.fetch(hash);

    return { slides, config: fullConfig[`e-${entityId}`]?.data };
  }

  static async update(
    section: TSection,
    modifiedSlide: TSlide,
    hash: string,
    entityId: number | null,
    config?: TUserConfigs,
  ): Promise<{ slides: TDynamicSlideDto[]; config?: TUserConfigs }> {
    const { config: updatedConfig } = await DynamicSlidesApi.delete(
      modifiedSlide.id,
      hash,
      modifiedSlide.entityId,
      config,
    );

    const { slides, config: userConfig } = await DynamicSlidesApi.create(
      section,
      modifiedSlide,
      hash,
      entityId,
      updatedConfig,
      modifiedSlide.id,
    );

    return { slides, config: userConfig };
  }

  static async delete(
    slideId: string,
    hash: string,
    entityId?: number | null,
    config?: TUserConfigs,
  ): Promise<{ slides: TDynamicSlideDto[]; config?: TUserConfigs }> {
    await DynamicSlidesApi.deleteSlide(slideId);
    await CustomizationSettingsApi.deleteSlide(slideId);

    const slides = (await DynamicSlidesApi.fetchAll(hash)) ?? [];
    const fullConfig = await CustomizationSettingsApi.fetch(hash);

    return { slides, config: fullConfig[`e-${entityId}`]?.data };
  }

  static async fetchAll(hash: string): Promise<TDynamicSlideDto[]> {
    const res = await corePresentApi.get(ENDPOINT, {}, { hash });

    const mapped = mapApiToEntity(res);
    return removeDuplicates(mapped);
  }

  static async fetchAllOfEntityType(
    hash: string,
    entityId: number | null,
  ): Promise<TDynamicSlideDto[]> {
    const res = await DynamicSlidesApi.fetchAll(hash);

    return res.filter(dynamicSlide => dynamicSlide.slide.entityId === entityId);
  }

  static async deleteSlide(uuid: string) {
    const res = await corePresentApi.delete(`${ENDPOINT}/deleteConfig`, { id: uuid });

    return res;
  }
}
