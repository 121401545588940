import styled from 'styled-components';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../components/Common/V2/Button/Button';
import { PresentationApi } from '../../Presentation/api/PresentationApi';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { useCategory } from '../../../providers/providers/CategoryProvider';
import { useUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { useOrder } from '../../../providers/providers/OrderProvider';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { TPresentation, TPresentationMode, TPresentationModeConfig } from '../../../types';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../providers/providers/PresentationModeProvider';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { usePresentationMeta } from '../../../providers/providers/PresentationMetaProvider';
import { useInvalidatePresentation } from '../../../hooks/usePresentation';

interface Props {
  isFetching: boolean;
}
export const PresentationEditActions: React.FC<Props> = ({ isFetching }) => {
  const { hash } = useParams<{ hash: string }>();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const ui = useUiConfigs();
  const { presentationType } = usePresentationType();
  const { category } = useCategory();
  const { presentationMode } = usePresentationMode();
  const { getOrderForMode } = useOrder();
  const { getSectionsForMode } = useSections();
  const { slides: slidesMap } = useSlides();
  const { meta } = usePresentationMeta();

  const queryClient = useQueryClient();

  const invalidatePresentation = useInvalidatePresentation(hash);

  const onPresentAndShare = async () => {
    setIsLoading(true);
    const presentation = queryClient.getQueryData<TPresentation>(['presentation', hash]);
    if (!presentation) return;

    const getModes = () => {
      const modesConfig: Partial<Record<TPresentationMode, TPresentationModeConfig>> = {};
      const modes: TPresentationMode[] = ['regular', 'cma', 'oneSheeter'];

      modes.forEach(mode => {
        const modeOrderConfig = getOrderForMode(mode);
        const modeSectionsConfig = getSectionsForMode(mode);

        if (!modeOrderConfig || !modeSectionsConfig) return;

        modesConfig[mode] = { order: modeOrderConfig, sections: modeSectionsConfig };
      });

      return modesConfig;
    };

    const id = presentation.id ? presentation.id : '';

    await PresentationApi.update(id, {
      ...presentation,
      category,
      ui,
      mode: presentationMode,
      slidesMap,
      meta,
      ...getModes(),
    });

    await PresentationApi.triggerPdfGeneration(id);
    invalidatePresentation();
    setIsLoading(false);
    push(`/presentation/${hash}/dashboard`);
  };

  const onPrevious = () => {
    const isWtl = presentationType === PRESENTATION_TYPES.WIN_THE_LISTING;
    const isWto = presentationType === PRESENTATION_TYPES.WIN_THE_OFFER;

    if (isWtl || isWto) {
      push(
        `/create/result/${hash}?step=report&type=${presentationType}&presentation=${hash}&action=update`,
      );
      return;
    }

    const isGeneral = presentationType === PRESENTATION_TYPES.PRESENT_INFO;
    if (isGeneral) {
      push('/');
    }
  };

  return (
    <>
      <PrevButton
        variant='secondary'
        prefixIcon={<FontAwesomeIcon icon={['fas', 'chevron-left']} />}
        onClick={onPrevious}
        style={{ marginRight: 10 }}
        disabled={isLoading || isFetching}
      >
        Previous
      </PrevButton>
      <Button
        variant='primary'
        disabled={isLoading || isFetching}
        onClick={onPresentAndShare}
        isLoading={isLoading}
      >
        Present & Share
      </Button>
    </>
  );
};

const PrevButton = styled(Button)`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;
