import { batch, useDispatch, useSelector } from 'react-redux';
import { resultOperations } from '../../../../store/result';

export function useResultRebuildStateSetter() {
  const dispatch = useDispatch();

  const rebuildData = useSelector(({ report }) => report.rebuildData);

  return (reportDetailed, onSuccess) => {
    const properties = [
      ...(reportDetailed.properties?.active ?? []),
      ...(reportDetailed.properties?.pending ?? []),
      ...(reportDetailed.properties?.sold ?? []),
    ];

    batch(async () => {
      dispatch(resultOperations.setHighlighted([]));

      let excludedIds = properties.filter(p => p.excluded).map(p => p.id);
      excludedIds = [...new Set(excludedIds.concat(rebuildData.request.excluded_ids || []))];
      dispatch(resultOperations.setExcluded(excludedIds));

      if (rebuildData?.report_state.custom_highlights?.length > 0) {
        dispatch(resultOperations.setCustomMarkers(rebuildData.report_state.custom_highlights));
      }

      if (rebuildData?.report_state?.highlights?.length > 0) {
        const validHighlights = rebuildData.report_state.highlights.filter(
          hId => !!properties.find(p => p.id === hId && !p.excluded),
        );

        dispatch(resultOperations.setHighlightsByIds(validHighlights));
        dispatch(resultOperations.setThumbsUp([]));
        dispatch(resultOperations.setDefaultThumbsUp());
      } else {
        dispatch(resultOperations.setDefaultHighlights());
      }

      if (rebuildData?.report_state?.categories?.length > 0) {
        dispatch(resultOperations.setCategoriesByIds(rebuildData.report_state.categories));
        dispatch(resultOperations.setCustomEstimates(rebuildData.report_state.custom_categories));
      } else {
        dispatch(resultOperations.setDefaultCategories());
      }

      setTimeout(() => onSuccess?.(), 1000);
    });
  };
}
