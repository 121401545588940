import { connect } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import styles from './CompResults.module.scss';
import { resultOperations } from '../../../../store/result';
import { CompsPerformance } from './CompsPerformance/CompsPerformance';
import { useResultRebuildStateSetter } from './useResultRebuildStateSetter';

import { PropertyModal } from '../../../../../../../features/report/components/PropertyModal/PropertyModal';

import { isPropertyModalOpenAtom } from '../../../../../../../features/report/state/isPropertyModalOpenAtom';
import { useSyncStateWithRedux } from '../../../../../../../features/report/hooks/useSyncStateWithRedux';
import { ResultsHeader } from '../../../../../../../features/report/results-header/ResultsHeader';
import { ScaleOverviewReport } from '../../../../../../../features/report/scale-overview/ScaleOverviewReport';

export const CompResultsComponent = ({
  setDefaultHighlights,
  setDefaultCategories,
  rebuildData,
  setHighlighted,
  setCategories,
  setCustomEstimates,
  setCustomMarkers,
  setActivePropertyType,
  setExcluded,
  properties,
  cacheStore,
  reportDetailed,
}) => {
  const setResultRebuildState = useResultRebuildStateSetter();
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);

  useSyncStateWithRedux();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHighlighted([]);
    setCategories([]);
    setExcluded([]);
    setActivePropertyType('active');
    setCustomEstimates([]);
    setCustomMarkers([]);

    if (Object.entries(rebuildData) && undefined !== rebuildData.request) {
      setResultRebuildState(reportDetailed);
    } else {
      const excludedIds = properties.filter(p => p.excluded).map(p => p.id);
      setExcluded(excludedIds);
      setDefaultHighlights();
      setDefaultCategories();
    }

    window.addEventListener('unload', () => cacheStore());
    return () => cacheStore();
  }, []);

  return (
    <>
      <ResultsHeader />
      <div className={styles.compResults}>
        <ScaleOverviewReport />
        <CompsPerformance />

        <PropertyModal
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      </div>
    </>
  );
};

const stateToProps = ({ report }) => ({
  rebuildData: report.rebuildData,
  reportDetailed: report.reportDetailed,
  properties: [
    ...(report.reportDetailed?.properties?.active ?? []),
    ...(report.reportDetailed?.properties?.pending ?? []),
    ...(report.reportDetailed?.properties?.sold ?? []),
  ],
});

const dispatchToProps = dispatch => ({
  setDefaultHighlights: () => dispatch(resultOperations.setDefaultHighlights()),
  setCustomMarkers: markers => dispatch(resultOperations.setCustomMarkers(markers)),
  setCustomEstimates: estimates => dispatch(resultOperations.setCustomEstimates(estimates)),
  setDefaultCategories: () => dispatch(resultOperations.setDefaultCategories()),
  cacheStore: () => dispatch(resultOperations.cacheStore()),
  onHighlight: property => dispatch(resultOperations.onHighlight(property, false)),
  setHighlighted: a => dispatch(resultOperations.setHighlighted(a)),
  setCategories: a => dispatch(resultOperations.setSelectedCategories(a)),
  setExcluded: a => dispatch(resultOperations.setExcluded(a)),
  setActivePropertyType: t => dispatch(resultOperations.setActivePropertyType(t)),
});

export const CompResults = connect(stateToProps, dispatchToProps)(CompResultsComponent);
