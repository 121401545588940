import { useState } from 'react';
import customEstimateIcon from './custom-estimates-icon.png';
import styled from 'styled-components';
import { Input } from '../../../../pages/PresentationCreate/dash/view/components/Input/Input';
import { MoneyInput } from '../../../../pages/PresentationCreate/dash/view/components/Input/MoneyInput';
import { useAtom, useAtomValue } from 'jotai';
import { customEstimatesAtom } from '../../state/customEstimatesAtom';
import { TProperty } from '../../../../types';
import { subjectAtom } from '../../state/subjectAtom';
import { useDispatch } from 'react-redux';
import { resultOperations } from '../../../../pages/PresentationCreate/dash/store/result';

const adaptCustomEstimate = (name: string, value: number, subject: TProperty) => ({
  name,
  value,
  valuePerSqft: Math.round(value / subject.size),
  icon: customEstimateIcon,
  id: `custom_estimate_${Math.random().toString(36).substring(2, 15)}`,
});

export const CustomEstimates = () => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const [estimates] = useAtom(customEstimatesAtom);
  const subject = useAtomValue(subjectAtom);

  const dispatch = useDispatch();

  const onAdd = () => {
    if (!name || !value) {
      return null;
    }

    dispatch(
      resultOperations.setCustomEstimates([
        ...estimates,
        adaptCustomEstimate(name, Number(value), subject.property),
      ]),
    );

    setName('');
    setValue('');
  };

  const remove = (index: number) => {
    const filtered = [...estimates];
    filtered.splice(index, 1);
    dispatch(resultOperations.setCustomEstimates(filtered));
  };

  return (
    <Container>
      <Title>
        Manually add multiple “public” estimates to your report. <br />
        <Example>
          Whether from another site (Realtor.com, Redfin, etc) or from a neighbor, friend, family
          member, etc to address the “My Aunt Linda thinks we should list for…” conversation and
          show prominently on the scale.
        </Example>
      </Title>

      {estimates.map(({ name, value }, i) => (
        <EstimatePair key={i}>
          <InputWrapper>
            <Input value={name} disabled noError />
          </InputWrapper>
          <InputWrapper>
            <MoneyInput value={value} disabled noError />
          </InputWrapper>
          <Close onClick={() => remove(i)}>X</Close>
        </EstimatePair>
      ))}

      <EstimatePair>
        <InputWrapper>
          <Input placeholder='Estimate Name' value={name} onChange={setName} />
        </InputWrapper>
        <InputWrapper>
          <MoneyInput placeholder='Estimate Price' value={value} onChange={setValue} />
        </InputWrapper>
      </EstimatePair>

      <Add>
        <span onClick={onAdd}>Add +</span>
      </Add>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  margin-top: 30px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary.textLight};
  letter-spacing: 0.01em;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  padding-right: 5px;
  margin-top: 10px;
  min-height: 30px;
`;

const Example = styled.span`
  opacity: 0.7;
`;

const EstimatePair = styled.div`
  display: flex;
  margin-top: 5px;
`;

const Add = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    cursor: pointer;
    padding-right: 5px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const InputWrapper = styled.div`
  padding: 3px 5px;
  font-size: 14px;
  width: 100%;
`;

const Close = styled.span`
  padding-left: 4px;
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
`;
