import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Environment } from '../DevelopmentUtilities/Environment';

export class ErrorMonitoring {
  static init() {
    if (!Environment.isProduction()) return;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0,
      ignoreErrors: [/fromLatLngToDivPixel/i],
    });
  }
}
