import * as creators from './actions';
import { ReportDetailedCommunicator } from '../../communicators/ReportDetailed/ReportDetailedCommunicator';
import { resultOperations } from '../result';

export const setRebuildData = data => creators.setRebuildData(data);
export const setReportDetailed = reportDetailed => {
  const useDtcInsteadDomForSold = !!reportDetailed?.properties?.sold?.find(
    p => p.daysOnMarket === false || p.daysOnMarket === null,
  );

  return creators.setReportDetailed({
    ...reportDetailed,
    useDtcInsteadDomForSold,
  });
};

const rebuildSelectedCategories = (report, selectedCategories) => {
  const categoriesValues = {
    ...report.compAverages,
    ...report.estimates,
    ...report.flags,
  };

  return selectedCategories
    .filter(category => categoriesValues[category.id])
    .map(category => ({
      ...category,
      value: categoriesValues[category.id],
      valuePerSqft: categoriesValues[category.id + '_per_sqft'],
    }));
};

export const updateReport =
  (additionalPayload = {}) =>
  async (dispatch, getState) => {
    const { search, result } = getState();

    const updatedReport = await ReportDetailedCommunicator.build({
      ...search.api,
      ...additionalPayload,
      additional_ids: search?.api?.additional_ids || [],
      excluded_ids: result.excluded,
    });

    dispatch(setReportDetailed(updatedReport));

    const selCat = rebuildSelectedCategories(updatedReport, result.selectedCategories);
    dispatch(resultOperations.setSelectedCategories(selCat));

    return updatedReport;
  };

export const resetReport = () => dispatch => {
  dispatch(setRebuildData({}));
  dispatch(setReportDetailed({}));
};
