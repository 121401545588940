import { useHistory } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import styled from 'styled-components';

import { getDefaultHighlightsAndThumbsUp } from '../../../../../pages/PresentationCreate/dash/store/result/operations';

import {
  ReportCompAverages,
  ReportEstimates,
  ReportFlagValues,
  TCompConfigItem,
  TProperty,
  TReportSelectedCategory,
} from '../../../../../types';
import { useShowPropertyDetails } from '../../../hooks/useShowPropertyDetails';
import { extractPropertyGroups } from '../../../services/extractPropertiesGroups';
import { extractFilteredProperties } from '../../../services/extractFilteredProperties';
import { pricePerSqftModeAtom } from '../../../state/pricePerSqftModeAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import { excludedPropertyIdsAtom } from '../../../state/excludedPropertyIdsAtom';
import { filteredPropertiesAtom } from '../../../state/filteredPropertiesAtom';
import { customMarkersAtom } from '../../../state/customMarkersAtom';
import { selectedCategoriesAtom } from '../../../state/selectedCategoriesAtom';
import { customEstimatesAtom } from '../../../state/customEstimatesAtom';
import { subjectAtom } from '../../../state/subjectAtom';
import { flagsAtom } from '../../../state/flagsAtom';
import { BottomRangeMarker } from './BottomRangeMarker';
import { calculateOffset } from '../services/offsetCalculator';
import { sortScaleProperties } from '../services/sortScaleProperties';
import { getCompsConfigAsCollection } from '../../../results-tuning/services/getCompsConfigAsCollection';
import { useHighlightedProperties } from '../../../state/highlighted-properties/useHighlightedProperties';
import { isAdjustedPriceModeAtom } from '../../../state/isAdjustedPriceModeAtom';
import { compAveragesAtom } from '../../../state/compAveragesAtom';
import { getAveragesAdjustedPrice } from '../services/getAveragesAdjustedPrice';

export const getCustomizedDataFromConfig = (
  config: TCompConfigItem[],
  id: keyof ReportCompAverages | keyof ReportEstimates | keyof ReportFlagValues,
): any => {
  const configItem = config?.find(c => c.id === id);

  if (!configItem) return;

  return configItem?.customizedData;
};

interface RangeBottomSelectedProps {
  overrideMode?: boolean;
  defaultMarkersOnly?: boolean;
  isPdf?: boolean;
  overridePlotBy?: boolean;
}

export const BottomRangeSelected = ({
  overrideMode,
  defaultMarkersOnly = false,
  isPdf = false,
  overridePlotBy = false,
}: RangeBottomSelectedProps) => {
  const { push, location } = useHistory();
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);
  const propertiesAtom = useAtomValue(reportPropertiesAtom);
  const excluded = useAtomValue(excludedPropertyIdsAtom);
  const customMarkers = useAtomValue(customMarkersAtom);
  const selectedCategories = useAtomValue(selectedCategoriesAtom);
  const customEstimates = useAtomValue(customEstimatesAtom);
  const subject = useAtomValue(subjectAtom);
  const flags = useAtomValue(flagsAtom);
  const isAdjPrice = useAtomValue(isAdjustedPriceModeAtom);

  const useAdjPrice = isPdf ? Boolean(overridePlotBy) : isAdjPrice;

  const compAverages = useAtomValue(compAveragesAtom);

  const setFilteredProperties = useSetAtom(filteredPropertiesAtom);
  const { showPropertyDetails } = useShowPropertyDetails();

  const properties = extractPropertyGroups(propertiesAtom);
  const highlighted = useHighlightedProperties();

  const highlights = [...highlighted, ...customMarkers];
  const categories = [...selectedCategories, ...customEstimates];

  const baseMarkerHeight = isPdf ? 60 : 50;
  const numberOfSelected = highlights.length + categories.length;
  const pricePerSqFtModeToUse = isPdf ? Boolean(overrideMode) : pricePerSqftMode;

  const highlightsToUse = defaultMarkersOnly
    ? getDefaultHighlightsAndThumbsUp(properties, { excluded }).highlights
    : highlights;

  const { sortedCategories, sortedHighlights } = sortScaleProperties({
    categories,
    highlights: highlightsToUse,
    useSqft: pricePerSqFtModeToUse,
    defaultsOnly: defaultMarkersOnly,
  });

  const calculateHeight = (
    numberOfSelected: number,
    markerBaseHeight: number,
    selectionsLength: number,
  ) => {
    switch (true) {
      case numberOfSelected > 20:
        return selectionsLength * (markerBaseHeight - 17);
      case numberOfSelected > 15:
        return selectionsLength * (markerBaseHeight - 15);
      default:
        return selectionsLength * markerBaseHeight;
    }
  };

  const buildContainerStyles = (categories: any) => {
    if (numberOfSelected < 2) {
      return {
        width: `${categories.length * 30}px`,
        height: numberOfSelected === 0 ? '0' : '50px',
      };
    }

    return {
      width: '100%',
      height: isPdf
        ? `${calculateHeight(numberOfSelected, baseMarkerHeight, categories.length)}px`
        : `${categories.length * baseMarkerHeight}px`,
    };
  };

  const compsConfig = getCompsConfigAsCollection();

  const onMarkerClickV2 = (clickedProperty: TReportSelectedCategory) => {
    const customizedData = getCustomizedDataFromConfig(compsConfig, clickedProperty.id);

    if (!customizedData) return alert('Something went wrong');

    const { getProperties, getFlagsProperties } = customizedData;
    let filteredProperties = getProperties?.(properties, pricePerSqftMode, subject.property);

    if (customizedData.source === 'flags' && flags) {
      const params = new URLSearchParams(location.search);
      params.append('source', 'flag');
      push({ pathname: location.pathname, search: '?' + params.toString() });
      filteredProperties = getFlagsProperties?.(flags);
    }

    let highlightProperty = Object.values(filteredProperties || {})
      ?.filter((p: any) => p && p.length)
      ?.flat()
      ?.filter((p: any) => !p.excluded)?.[0];
    if (customizedData.getHighlight) {
      highlightProperty = customizedData.getHighlight(properties, pricePerSqftMode, useAdjPrice);
    }

    if (!highlightProperty) return alert('No properties match this criteria');
    setFilteredProperties(filteredProperties ? extractFilteredProperties(filteredProperties) : []);
    showPropertyDetails(highlightProperty as TProperty);
  };

  return (
    <RangeHighlightsWrapper isCentered={numberOfSelected < 2} isPdf={isPdf}>
      <div style={{ position: 'relative', ...buildContainerStyles(sortedCategories) }}>
        {sortedCategories.map((category, index) => {
          const { id, name, value, valuePerSqft, icon } = category;
          const customizedData = getCustomizedDataFromConfig(compsConfig, category.id);
          const cost = pricePerSqFtModeToUse ? valuePerSqft : value;

          const isCompAverage = compAverages && Object.hasOwn(compAverages, id);

          const adjusted = isCompAverage
            ? getAveragesAdjustedPrice({
                id,
                compAverages: compAverages,
                useSqftPrice: pricePerSqFtModeToUse,
              })
            : null;

          const price = useAdjPrice ? adjusted || cost : cost;

          let count =
            customizedData && customizedData.getCount
              ? customizedData.getCount(properties, subject?.property)
              : undefined;

          if (customizedData?.source === 'flags') {
            count =
              customizedData && customizedData.getFlaggedCount
                ? customizedData.getFlaggedCount(flags)
                : undefined;
          }

          return (
            <BottomRangeMarker
              key={`${id}_${index}`}
              text={name}
              height={baseMarkerHeight * (sortedCategories.length - index)}
              offsetLeft={calculateOffset(
                index,
                sortedCategories,
                sortedHighlights,
                price,
                numberOfSelected,
                pricePerSqFtModeToUse,
                useAdjPrice,
              )}
              price={price}
              icon={icon}
              count={count}
              onClick={customizedData && !isPdf ? () => onMarkerClickV2(category) : undefined}
              isPdf={isPdf}
            />
          );
        })}
      </div>
    </RangeHighlightsWrapper>
  );
};

const RangeHighlightsWrapper = styled.div<{ isCentered: boolean; isPdf?: boolean }>`
  position: relative;
  width: 100%;
  top: ${({ isPdf }) => (isPdf ? '-12px' : '-15px')};
  padding: ${({ isPdf }) => (isPdf ? '0 90px 0 220px' : '0 180px 0 260px')};

  ${({ isCentered }) =>
    isCentered &&
    `display: flex;
    justify-content: center;`}
`;
