import styled from 'styled-components';

import { RangeHighlights } from '../components/RangeHighlights';
import { ScaleMiddleLine } from '../components/ScaleMiddleLine';
import { BottomRangeSelected } from '../components/BottomRangeSelected';

export const ScalePDF = ({ pricePerSqftMode = false, isAdjustedPricePlotBy = false }) => (
  <Container>
    <Content>
      <RangeHighlights
        isPdf
        overrideMode={pricePerSqftMode}
        overridePlotBy={isAdjustedPricePlotBy}
      />
    </Content>
    <ScaleMiddleLine isPdf overrideMode={pricePerSqftMode} overridePlotBy={isAdjustedPricePlotBy} />
    <Content>
      <BottomRangeSelected
        isPdf
        overrideMode={pricePerSqftMode}
        overridePlotBy={isAdjustedPricePlotBy}
      />
    </Content>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  width: 90%;
`;
