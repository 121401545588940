import styled, { AnyStyledComponent } from 'styled-components';

import { ReactComponent as layer_1_svg } from './assets/layer_1.svg';
import { ReactComponent as layer_2_svg } from './assets/layer_2.svg';
import { ReactComponent as layer_3_svg } from './assets/layer_3.svg';
import { useReverseHeader } from '../../../../../../hooks/useReverseHeader';

export const Layer1 = styled(layer_1_svg as AnyStyledComponent)(({ infoPlacement }) => ({
  position: 'absolute',
  zIndex: 6,
  height: 'auto',
  transform: `scale(.975) translate(${useReverseHeader() ? '1.25%' : '-1.25%'}, ${
    infoPlacement === 'bottom' ? '-' : ''
  }17.5px) ${infoPlacement === 'bottom' ? 'scaleY(-1)' : ''}`,
}));

export const Layer2 = styled(layer_2_svg as AnyStyledComponent)(({ infoPlacement }) => ({
  position: 'absolute',
  left: '50%',
  transform: `translate(-48.75%, ${infoPlacement === 'bottom' ? '-' : ''}22.5px) scale(.975) ${
    infoPlacement === 'bottom' ? 'scaleY(-1)' : ''
  }`,
  transformOrigin: 0,
  zIndex: 8,
}));

export const Layer3 = styled(layer_3_svg as AnyStyledComponent)(({ infoPlacement }) => ({
  position: 'absolute',
  left: '50%',
  transform: `translate(-48.75%, ${infoPlacement === 'bottom' ? '-' : ''}25px) scale(.975) ${
    infoPlacement === 'bottom' ? 'scaleY(-1)' : ''
  }`,
  transformOrigin: 0,
  zIndex: 9,
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  marginBottom: 0,
  paddingRight: useReverseHeader() ? 40 : 20,
  paddingLeft: useReverseHeader() ? 20 : 0,
  zIndex: 8,
  flexDirection: useReverseHeader() ? 'row-reverse' : 'row',
}));

export const BrokerInfoWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 6,
  width: '33.33%',
  paddingLeft: useReverseHeader() ? 0 : '3%',
  paddingRight: !useReverseHeader() ? 0 : '3%',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 6,
  width: '33.33%',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: useReverseHeader() ? 0 : '8%',
  paddingRight: !useReverseHeader() ? 0 : '8%',
}));
