import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { CurrentUserService } from '../../../services/CurrentUserService';
import { IUserApiTestimonial, TKvCoreEntity, TUser } from '../../../types';
import { sanitizeEmail } from '../services/sanitizeEmail';
import { useAuthMe } from '../../../hooks/useAuthMe';

type TApiUserSetter = (apiUser: any) => void;

type UserContextProps = TUser & {
  setApiUser: TApiUserSetter;
  isCanadaUser: boolean;
  feedIds: string[];
  setSlideProfileImage: (base64Img: string) => void;
  setUser: (user: TUser | null) => void;
};

type UserContextApiUserSetterProps = TUser & {
  setApiUser: TApiUserSetter;
};

type TUserContext = UserContextProps | UserContextApiUserSetterProps | null;

const UserContext = createContext<TUserContext>(null);

const extractEntities = (entities: string, type: string): TKvCoreEntity[] => {
  if (!entities?.length) return [];

  return JSON.parse(entities)
    ?.filter((e: any) => e.type === type)
    .map((e: any) => ({
      id: e?.id,
      name: e?.name,
      isAdmin: !!e?.is_admin,
    }));
};

const extractSingleEntity = (entities: string, type: string): TKvCoreEntity => {
  return extractEntities(entities, type)[0];
};

const mapApiUserToUserEntity = (apiUser: any) => {
  if (!apiUser) return null;

  const superaccounts = extractEntities(apiUser.entities, 'superaccount');
  const account = extractSingleEntity(apiUser.entities, 'account');
  const offices = extractEntities(apiUser.entities, 'office');
  const teams = extractEntities(apiUser.entities, 'team');

  const userTestimonials = apiUser?.testimonials;

  const testimonials = userTestimonials ? JSON.parse(userTestimonials) : [];

  const userName = apiUser.name ? apiUser.name : `${apiUser.first_name} ${apiUser.last_name}`;
  const userImage = apiUser.image ? apiUser.image : apiUser.avatar;

  const adaptedTestimonials = testimonials.length
    ? testimonials.map((t: IUserApiTestimonial) => {
        return {
          text: t.testimonial,
          client: t.who,
          isHidden: false,
        };
      })
    : [];

  return {
    userId: `${apiUser.id}`,
    name: userName,
    email: sanitizeEmail(apiUser.email),
    phone: apiUser.phone,
    position: apiUser.title ?? 'Real Estate Agent',
    image: userImage,
    slideProfileImage: apiUser.slideProfileImage ?? apiUser.avatar,
    website: apiUser.website ?? '',
    cal_bre: apiUser.license ?? '',
    is_canada_user: apiUser.is_canada_user,
    feedIds: apiUser.feed_ids ?? [],
    company: apiUser.company ?? '',
    address: apiUser.address ?? '',
    city: apiUser.city ?? '',
    zip: apiUser.zip ?? '',
    hash: apiUser.hash,
    offices,
    state: apiUser.state,
    teams,
    account,
    superaccounts,
    primaryEntity: apiUser.kvcore_primary_entity,
    bio: apiUser.bio,
    testimonials: adaptedTestimonials.length ? adaptedTestimonials.slice(0, 3) : [],
    accountId: apiUser.kvcore_account_id,
    companyLogo: apiUser.company_logo,
    companyColor: apiUser.company_color,
    useSpecialBranding: Boolean(apiUser.use_special_branding),
    kvcoreParentAccountId: apiUser?.kvcore_parent_account_id ?? null,
    kvcoreUserId: apiUser?.kvcore_user_id ?? null,
    shouldHideIntercomButton: apiUser?.should_hide_intercom_button,
    signature: apiUser?.signature,
    showSignatureOnOutgoingEmails: apiUser?.show_signature_on_outgoing_emails,
  };
};

interface UserProviderProps {
  initValue?: TUser;
  fetch?: boolean;
}

export const UserProvider: React.FC<UserProviderProps> = ({
  initValue,
  children,
  fetch = false,
}) => {
  const [_user, setUser] = useState(initValue ?? null);

  const { data: apiUser } = useAuthMe(fetch);

  const setSlideProfileImage = useCallback(
    (base64: string) => {
      if (!_user) return;

      const updatedUser = { ..._user, slideProfileImage: base64 };
      CurrentUserService.setUser(updatedUser);
      setUser(updatedUser);
    },
    [_user],
  );

  const setApiUser = React.useCallback((apiUser?: any) => {
    const userEntity = mapApiUserToUserEntity(apiUser) ?? null;
    CurrentUserService.setUser(userEntity);
    setUser(userEntity ?? null);
  }, []);

  useEffect(() => {
    (async function () {
      if (!apiUser || !fetch) return;

      setApiUser(apiUser);
    })();
  }, [apiUser, fetch, setApiUser]);

  useEffect(() => {
    CurrentUserService.setUser(_user);
  }, [_user]);

  const value = React.useMemo(
    () =>
      !_user?.userId
        ? { setApiUser }
        : {
            ..._user,
            setSlideProfileImage,
            isCanadaUser: Boolean(_user?.is_canada_user),
            setUser,
            setApiUser,
          },
    [_user, setApiUser, setSlideProfileImage],
  );

  return <UserContext.Provider value={value as TUserContext}>{children}</UserContext.Provider>;
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserContext');
  }

  return (!context?.userId ? null : context) as UserContextProps;
};

export const useSetApiUser = (): TApiUserSetter => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useSetApiUser must be used within a UserContext');
  }

  return context?.setApiUser as TApiUserSetter;
};
