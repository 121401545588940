import { UserSettings } from '../../types';
import { Theme } from './types';

export const getUpdatedWhiteLabelTheme = (theme: Theme, userSettings?: UserSettings): Theme => {
  const hasWL = Boolean(userSettings?.whiteLabelSettings);

  if (!hasWL) return theme;

  const { colors } = theme;
  const { v2 } = colors;
  const { gradient } = v2;
  const primary = userSettings?.whiteLabelSettings?.primaryColor;

  const updatedPrimary = primary ? primary : v2.primary;
  const updatedAccent = primary ? primary : v2.accent;
  const updatedAccentGradient = primary
    ? `linear-gradient(137deg, ${primary} 0%, ${primary} 96.56%)`
    : gradient.accent;
  const updatedBorderGradient = primary
    ? `linear-gradient(white, white), linear-gradient(to top right, ${primary} 0%, ${primary} 100%)`
    : gradient.border;
  const updatedBackgroundGradient = primary
    ? `var(--White-Label-BG-Gradient, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 41.16%), linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(221, 221, 221, 0.30) 22%, rgba(177, 177, 177, 0.30) 82.5%, rgba(255, 255, 255, 0.30) 100%), #FFF);`
    : gradient.background;

  return {
    ...theme,
    colors: {
      ...colors,
      v2: {
        ...v2,
        primary: updatedPrimary,
        accent: updatedAccent,
        gradient: {
          ...gradient,
          accent: updatedAccentGradient,
          border: updatedBorderGradient,
          background: updatedBackgroundGradient,
        },
      },
    },
  };
};
