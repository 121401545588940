import * as types from './types';
import { PROPERTIES_TYPES } from '../../view/constants/propertyType';

export const selectedCategories = (data = [], action) => {
  switch (action.type) {
    case types.SELECTED_CATEGORIES_SET:
      return action.payload;
    default:
      return data;
  }
};

export const activePropertyType = (data = PROPERTIES_TYPES.ACTIVE, action) => {
  switch (action.type) {
    case types.ACTIVE_PROPERTY_TYPE_SET:
      return action.payload;
    default:
      return data;
  }
};

export const filteredProperties = (data = null, action) => {
  switch (action.type) {
    case types.SET_FILTERED_PROPERTIES:
      return action.payload;
    default:
      return data;
  }
};

export const highlighted = (data = [], action) => {
  switch (action.type) {
    case types.HIGHLIGHTED_SET:
      return action.payload;
    default:
      return data;
  }
};
//const defOrderValue = { label: 'closest', value: 'distance', order: 'asc' };
const defOrderValue = {
  label: 'Most Recent',
  value: 'listDate',
  order: 'desc',
};
export const highlightOrder = (data = defOrderValue, action) => {
  switch (action.type) {
    case types.HIGHLIGHTED_ORDER_SET:
      return action.payload || defOrderValue;
    default:
      return data;
  }
};
export const compViewMode = (data = 'slider', action) => {
  switch (action.type) {
    case types.COMP_VIEW_MODE:
      return action.payload || data;
    default:
      return data;
  }
};

export const excluded = (data = [], action) => {
  switch (action.type) {
    case types.EXCLUDED_SET:
      return action.payload;
    default:
      return data;
  }
};

export const excludeInProgress = (data = false, action) => {
  switch (action.type) {
    case types.EXCLUDE_IN_PROGRESS:
      return action.payload !== undefined ? action.payload : data;
    default:
      return data;
  }
};

export const thumbsUp = (data = [], action) => {
  switch (action.type) {
    case types.THUMBS_UP_SET:
      return [...action.payload];
    default:
      return data;
  }
};

export const pricePerSqftMode = (ppsf = false, action) => {
  switch (action.type) {
    case types.PRICE_PER_SQFT_MODE_SET:
      return action.payload;
    default:
      return ppsf;
  }
};

export const selectedProperty = (property = null, action) => {
  switch (action.type) {
    case types.SELECTED_PROPERTY_SET:
      return action.payload ?? null;
    default:
      return property ?? null;
  }
};

export const customMarkers = (markers = [], action) => {
  switch (action.type) {
    case types.CUSTOM_MARKERS_SET:
      return action.payload;
    default:
      return markers;
  }
};

export const customEstimates = (estimates = [], action) => {
  switch (action.type) {
    case types.CUSTOM_ESTIMATES_SET:
      return action.payload;
    default:
      return estimates;
  }
};

export const isPropertyModalOpen = (isOpen = false, action) => {
  switch (action.type) {
    case types.SET_IS_PROPERTY_MODAL_OPEN:
      return action.payload;
    default:
      return isOpen;
  }
};

export const notes = (notes = [], action) => {
  switch (action.type) {
    case types.NOTES_SET:
      return [...action.payload];
    case types.NOTES_ADD:
      return [{ ...action.payload }, ...notes];
    default:
      return notes;
  }
};
