export const SELECTED_CATEGORIES_SET = 'result/SELECTED_CATEGORIES_SET';
export const ACTIVE_PROPERTY_TYPE_SET = 'result/ACTIVE_PROPERTY_TYPE_SET';
export const SET_FILTERED_PROPERTIES = 'result/SET_FILTERED_PROPERTIES';
export const HIGHLIGHTED_SET = 'result/HIGHLIGHTED_SET';
export const HIGHLIGHTED_ORDER_SET = 'result/HIGHLIGHTED_ORDER_SET';
export const COMP_VIEW_MODE = 'result/COMP_VIEW_MODE';
export const EXCLUDED_SET = 'result/EXCLUDED_SET';
export const EXCLUDE_IN_PROGRESS = 'result/EXCLUDE_IN_PROGRESS';
export const THUMBS_UP_SET = 'result/THUMBS_UP_SET';
export const PRICE_PER_SQFT_MODE_SET = 'result/PRICE_PER_SQFT_MODE_SET';
export const SELECTED_PROPERTY_SET = 'result/SELECTED_PROPERTY_SET';

export const CUSTOM_MARKERS_SET = 'result/CUSTOM_MARKERS_SET';
export const CUSTOM_ESTIMATES_SET = 'result/CUSTOM_ESTIMATES_SET';

export const SET_IS_PROPERTY_MODAL_OPEN = 'result/IS_PROPERTY_MODAL_OPEN';

export const NOTES_SET = 'result/NOTES_SET';
export const NOTES_ADD = 'result/NOTES_ADD';
