import { useAtomValue } from 'jotai';

import { propertyToMarker } from './propertyToMarker';
import { THighPropertyLight } from '../../../../types';
import { reportPropertiesAtom } from '../reportPropertiesAtom';
import { highlightedPropertiesAtom } from './highlightedPropertiesAtom';
import { extractPropertyGroups } from '../../services/extractPropertiesGroups';
import { extractHighlightedProperties } from '../../services/extractHighlightedProperties';
import { getPropertyId } from '../../services/getPropertyId';

export function useHighlightedProperties() {
  const highlightedPropertiesList = useAtomValue(highlightedPropertiesAtom);
  const reportPropertiesList = useAtomValue(reportPropertiesAtom);

  const propertyGroups = extractPropertyGroups(reportPropertiesList);
  const highlightedProperties = extractHighlightedProperties(
    propertyGroups,
    highlightedPropertiesList,
  );

  const highlightedMap = highlightedPropertiesList.reduce(
    (acc, p) => ({ ...acc, [p.id]: p }),
    {} as Record<string, THighPropertyLight>,
  );

  return (
    highlightedProperties?.map(p => propertyToMarker(p, highlightedMap[getPropertyId(p)]?.label)) ||
    []
  );
}
