import React from 'react';
import { ReactComponent as WaveLighter } from './assets/wave-light.svg';
import { ReactComponent as WaveDarker } from './assets/wave-darker.svg';
import { ReactComponent as Traditional_2 } from './assets/traditional_bg_2.svg';
import { ReactComponent as Traditional_1 } from './assets/traditional_bg_1.svg';
import { ContainerBackground, ContainerWaveLighter } from './TraditionalBackground.styles';
import { useUser } from '../../providers/UserProvider';
import { ACCOUNT_IDS } from '../../../../constants/accountIds';

interface Props {
  style?: React.CSSProperties;
  frame?: boolean;
}

export const TraditionalBackground: React.FC<Props> = ({ children, style, frame }) => {
  const user = useUser();

  const isWhiteBgColor = [user?.kvcoreParentAccountId, user?.accountId].includes(
    ACCOUNT_IDS.NEXT_HOME,
  );

  return (
    <ContainerBackground style={style} isWhiteBgColor={isWhiteBgColor}>
      <ContainerWaveLighter>{frame ? <Traditional_1 /> : <WaveLighter />}</ContainerWaveLighter>
      <ContainerWaveLighter>{frame ? <Traditional_2 /> : <WaveDarker />}</ContainerWaveLighter>
      {children}
    </ContainerBackground>
  );
};
