import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Lock } from '../../Lock/Lock';
import { getCategoryFonts } from './fonts';
import { useCanUpdateLockStatus } from '../../Lock/useCanUpdateLockStatus';
import { TSlideLockableValue } from '../../../../../../../../../../types';
import { Select } from './ExpandablesFonts.styles';
import { useCategory } from '../../../../../../../../../../providers/providers/CategoryProvider';
import { CustomizationSettingsExpandableCommon } from '../../../CustomizationModalStepOne.component';
import { SelectOption } from '../../../../../../../../../../components/Common/Select/Select.component';
import { useUiConfigFonts } from '../../../../../../../../../../providers/providers/UiConfigFontProvider';
import { useEntity } from '../../../../../../../../providers/EntityProvider';
import { getConfigLockedStatus } from '../../../services/getConfigLockedStatus';
import { useUser } from '../../../../../../../../../../components/Slide/providers/UserProvider';
import { Accordion } from '../../../../../../../../../../components/Common/V2/Accordion';
import { InfoBanner } from '../InfoBanner';
import { getShouldShowInfoBanner } from '../../../services/getShouldShowInfoBanner';

type ExpandablesFontsComponentProps = CustomizationSettingsExpandableCommon;

type TLocked = {
  header: boolean;
  body: boolean;
};

const createFontOption = ({ value }: TSlideLockableValue): SelectOption => ({
  label: value,
  value,
});

export const ExpandablesFontsComponent: React.FC<ExpandablesFontsComponentProps> = ({
  open,
  onToggle,
}) => {
  const { category } = useCategory();
  const { fonts, setFonts } = useUiConfigFonts();

  const { entity } = useEntity();
  const { primaryEntity, accountId } = useUser();
  const canLockHeader = useCanUpdateLockStatus(fonts.body.lockedStatus);
  const canLockBody = useCanUpdateLockStatus(fonts.body.lockedStatus);

  const categoryFonts = React.useMemo(
    () => getCategoryFonts(accountId, category),
    [accountId, category],
  );

  const categoryFontOptions = React.useMemo(() => {
    if (!categoryFonts) return [];

    return [
      {
        label: 'Preferred',
        options: categoryFonts.preferred.map(cf => ({
          label: cf.label,
          value: cf.label,
        })),
      },
      {
        label: 'Other',
        options: categoryFonts.other.map(cf => ({
          label: cf.label,
          value: cf.label,
        })),
      },
    ];
  }, [categoryFonts]);

  const [isFontsMenuOpen, setIsFontsMenuOpen] = useState(false);

  const [headerFont, setHeaderFont] = useState(
    createFontOption(fonts.header ?? categoryFonts?.preferred[0].label ?? 'Arial'),
  );
  const [bodyFont, setBodyFont] = useState(
    createFontOption(fonts.body ?? categoryFonts?.preferred[0].label ?? 'Arial'),
  );

  const [locked, setLocked] = useState({
    header: getConfigLockedStatus(entity, primaryEntity, fonts.header.lockedStatus),
    body: getConfigLockedStatus(entity, primaryEntity, fonts.body.lockedStatus),
  });

  const showBannerInfo = getShouldShowInfoBanner({
    canUpdateLock: canLockHeader || canLockBody,
    isLocked: locked.header || locked.body,
  });

  useEffect(onApply, [locked, headerFont, bodyFont, setFonts, entity]);

  const onFontLockChange = (field: keyof TLocked) => {
    setLocked(prev => ({ ...prev, [field]: !prev[field] }));
  };

  function onApply() {
    setFonts({
      header: {
        lockedStatus: { [`e-${entity}`]: entity !== null ? locked.header : undefined },
        value: String(headerFont.value),
      },
      body: {
        lockedStatus: { [`e-${entity}`]: entity !== null ? locked.body : undefined },
        value: String(bodyFont.value),
      },
    });
  }

  if (!category || !categoryFonts) return null;

  return (
    <Accordion
      title={'Fonts'.toUpperCase()}
      prefixIcon={<FontAwesomeIcon icon={'font'} />}
      open={open}
      onClick={() => onToggle('fonts')}
    >
      {showBannerInfo && <InfoBanner />}
      <Container>
        <Row>
          <Text>Header Font</Text>
          <SelectContainer>
            <Select
              selectedOption={headerFont}
              options={categoryFontOptions}
              disabled={locked.header}
              onChange={selectedOpt => setHeaderFont(selectedOpt as SelectOption)}
              styles={{ width: 'auto', flex: '1' }}
              setIsMenuOpen={setIsFontsMenuOpen}
              useDataFonts
            />
            <Lock
              onClick={() => onFontLockChange('header')}
              isLocked={!!locked.header}
              canLock={canLockHeader}
            />
          </SelectContainer>
        </Row>
        <Row>
          <Text>Body Font</Text>
          <SelectContainer>
            <Select
              selectedOption={bodyFont}
              options={categoryFontOptions}
              disabled={locked.body}
              onChange={selectedOpt => setBodyFont(selectedOpt as SelectOption)}
              styles={{ width: 'auto', flex: '1' }}
              setIsMenuOpen={setIsFontsMenuOpen}
              useDataFonts
            />
            <Lock
              onClick={() => onFontLockChange('body')}
              isLocked={!!locked.body}
              canLock={canLockBody}
            />
          </SelectContainer>
        </Row>
      </Container>
    </Accordion>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.v2.background};
  width: 100%;
  border-radius: 0 0 5px 5px;
`;

const Text = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const SelectContainer = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  gap: 10px;
`;
