import React, { useState } from 'react';
import styled from 'styled-components';

import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { Report } from '../../../../../../pages/PresentationCreate/dash';
import SellingPaceSlide from '../components/sellingPace/web/SellingPaceSlide';
import ActiveShelfLife from '../components/activeShelfLife/web/ActiveShelfLife';
import ActiveCompetition from '../components/activeCompetition/web/ActiveCompetition';
import PricingFavorability from '../components/pricingFavorability/web/PricingFavorability';
import PricePerSquareFootSlide from '../components/pricePerSquareFoot/web/PricePerSquareFootSlide';
import PricingRateOfChangeSlide from '../components/pricingRateOfChange/web/PricingRateOfChangeSlide';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { TRACKING_EVENTS, TrackingEvent } from '../../../../../../services/TrackingEvent';
import { useUser } from '../../../../providers/UserProvider';
import { BasicModal } from '../../../../../Common/BasicModal';

type WithCanadaUser = {
  isCanadaUser: boolean;
};

const Container = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const GaugesContainer = styled('div')<WithCanadaUser>(({ isCanadaUser }) => ({
  padding: 15,
  display: 'grid',
  gridTemplateColumns: 'repeat(6,  1fr)',
  gridGap: 20,
  '&>div': {
    gridColumn: 'span 2',
  },
  '& div:nth-child(4), & div:nth-child(5)': {
    gridColumn: `span ${isCanadaUser ? 3 : 2}`,
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  fontSize: 20,
  color: '#000',
  fontFamily: useUiConfigFontsHeader(),
  textTransform: 'uppercase',
  textAlign: 'center',
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const Description = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: '#000',
  marginBottom: 10,
  lineHeight: 1.5,
  fontFamily: useUiConfigFontsBody(),
  textAlign: 'center',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const overviewSlides: any = {
  shelfLife: ActiveShelfLife,
  pricePerSqft: PricePerSquareFootSlide,
  rate: PricingRateOfChangeSlide,
  favor: PricingFavorability,
  competition: ActiveCompetition,
  sellingPace: SellingPaceSlide,
};

const boxIdToLabelMap: any = {
  favor: 'Average List Price',
  competition: 'Fresh/Stale',
  sellingPace: 'Selling Pace',
  pricePerSqft: 'Price per SQFT',
  rate: 'Pricing Rate of Change',
  shelfLife: 'Current Active Shelf Life',
};

export const MarketOverview: React.FC = () => {
  const [activeOverview, setActiveOverview] = useState('');
  const user = useUser();
  const isCanadaUser = !!user?.isCanadaUser;

  const open = React.useCallback(function open(overview) {
    return () => {
      TrackingEvent.click(
        boxIdToLabelMap[overview] ?? 'gauge',
        TRACKING_EVENTS.GAUGES_ELEMENT_CLICKED,
      );
      setActiveOverview(overview);
    };
  }, []);

  const close = React.useCallback(function close() {
    setActiveOverview('');
  }, []);

  const Overview = overviewSlides[activeOverview];

  const { category } = useCategory();

  return (
    <Container>
      <Headline>Market Assessment</Headline>
      <Description>Click on any box to view further details.</Description>

      <GaugesContainer isCanadaUser={isCanadaUser}>
        <Report.Components.CompsPerformance.PricingFavorabilityGauge onClick={open('favor')} />
        <Report.Components.CompsPerformance.CompetitionGauge onClick={open('competition')} />
        <Report.Components.CompsPerformance.SellingPaceGauge onClick={open('sellingPace')} />
        {!isCanadaUser && (
          <Report.Components.CompsPerformance.PricePerSqftGauge onClick={open('pricePerSqft')} />
        )}
        <Report.Components.CompsPerformance.PricingRateOfChangeGauge onClick={open('rate')} />
        <Report.Components.CompsPerformance.ShelfLifeGauge onClick={open('shelfLife')} />
      </GaugesContainer>

      <BasicModal id={'marketOverview'} isOpen={!!activeOverview} onClose={close}>
        {Overview && <Overview category={category} />}
      </BasicModal>
    </Container>
  );
};
