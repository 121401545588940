import IntercomLib from 'react-intercom';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { useTheme } from 'styled-components';

export function Intercom() {
  const user = useUser();
  const { colors } = useTheme();

  const userForIntercom = {
    user_id: user?.kvcoreUserId ?? user?.userId ?? '',
    email: user?.email,
    name: user?.name,
    Product: 'dashCMA',
    dashCMA: 'true',
    alignment: 'right',
    action_color: colors.v2.primary,
    background_color: colors.v2.primary,
  };

  if (user.shouldHideIntercomButton) {
    return null;
  }

  return <IntercomLib appID='f1lcarew' {...userForIntercom} />;
}
