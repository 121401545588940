import { RawReport, TPricingRateOfChange } from '../../../types';

export function extractPricingRateOfChange(report: RawReport): TPricingRateOfChange {
  const rawData = report?.graphs?.rate_of_change || {};

  const props = rawData.props
    .map(({ address, list_price, sale_price, sale_date, id, adjusted_sale_price }) => ({
      id,
      list_price,
      sale_price,
      name: address,
      sale_date,
      adjusted_sale_price,
    }))
    .reverse();

  return {
    text: rawData.text,
    props,
  };
}
