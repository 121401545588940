import React from 'react';

import {
  useCriteriaIsOpen,
  useCriteriaIsOpenSetter,
} from '../../PropertyDetails/CriteriaIsOpenProvider';
import classes from './AdrSection.module.scss';
import { CRITERIAS } from '../../PropertyDetails/Criterias';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';

const Pill = ({ name = '', value, id = '', onClick = f => f }) => {
  return (
    <div id={id} className={classes.pill} onClick={onClick}>
      {name && <b>{name}:</b>} {value}
    </div>
  );
};

export const PillsPlaceholder = () => {
  const { isCanadaUser } = useUser();

  return (
    <div className={`${classes.pills} ${classes.placeholder}`}>
      <div id='search-pill-criteria' className={classes.criteria}>
        Comparable <br />
        Criteria
      </div>
      <Pill id='search-pill-propertyType' value='--' />
      <Pill id='search-pill-beds' value='--' />
      {!isCanadaUser && <Pill id='search-pill-size' value='--' />}
      <Pill id='search-pill-year' value='--' />
      <Pill id='search-pill-dates' value='--' />
    </div>
  );
};

export const Pills = ({ searchData }) => {
  const [,] = useCriteriaIsOpen('size');
  const { isCanadaUser } = useUser();

  const propertyTypes = () => {
    let ret = '';
    const el = Object.entries(searchData.propertyTypeSelections);
    el.forEach(element => {
      if (element[1].label) {
        ret += element[1].label + ', ';
      }
    });
    return ret.slice(0, -2);
  };

  const hasYearsRange =
    searchData.yearBuiltRangeSet &&
    !isNaN(searchData.yearBuiltFrom) &&
    !isNaN(searchData.yearBuiltTo);
  const yearBuilt = () => {
    if (hasYearsRange) {
      return `From: ${searchData.yearBuiltFrom}  To: ${searchData.yearBuiltTo}`;
    }
    return 'All Years';
  };

  const closedDate = () => {
    if (searchData.saleDateLimitRange && searchData.saleDateLimitRange.length === 2) {
      return `Sold: ${searchData.saleDateLimitRange[0]} - ${searchData.saleDateLimitRange[1]} months`;
    }

    return 'All Dates';
  };

  const size = () => {
    if (searchData.sizeRange && searchData.sizeRange.length === 2) {
      return (
        Math.round(searchData.sizeRange[0]) + ' to ' + Math.round(searchData.sizeRange[1]) + ' sqft'
      );
    }
    return 'All Size';
  };

  const totalSize = () => {
    if (
      searchData.totalSizeRange &&
      searchData.totalSizeRange.length === 2 &&
      searchData.totalSizeSet
    ) {
      return (
        parseFloat(searchData.totalSizeRange[0].toFixed(2)) +
        ' - ' +
        parseFloat(searchData.totalSizeRange[1].toFixed(2)) +
        ` ${searchData.totalSizeUnit}`
      );
    }
    return null;
  };

  const bedCount = () => {
    const beds = parseInt(searchData.beds);

    if (searchData.bedsRange && searchData.bedsRange.length === 2) {
      const from = beds + searchData.bedsRange[0];
      const to = beds + searchData.bedsRange[1];
      if (from === to) {
        return from + ' Beds';
      }
      return from + ' - ' + to + ' Beds';
    }
    return 'All Beds';
  };

  const setIsCriteriaOpen = useCriteriaIsOpenSetter();

  const openCriteria = React.useCallback(function openCriteria(criteria) {
    setIsCriteriaOpen(criteria, true);
  });

  return (
    <div className={classes.pills}>
      <div id='search-pill-criteria' className={classes.criteria}>
        Comparable <br />
        Criteria
      </div>
      {searchData.propertyTypeSelections && (
        <Pill
          id='search-pill-propertyType'
          value={propertyTypes()}
          onClick={() => openCriteria(CRITERIAS.PROPERTY_TYPE)}
        />
      )}
      {searchData.bedsRange && (
        <Pill
          id='search-pill-beds'
          value={bedCount()}
          onClick={() => openCriteria(CRITERIAS.BEDS)}
        />
      )}
      {!isCanadaUser && searchData.sizeRange && (
        <Pill id='search-pill-size' value={size()} onClick={() => openCriteria(CRITERIAS.SIZE)} />
      )}
      {searchData.yearBuilt && (
        <Pill
          id='search-pill-year'
          value={yearBuilt()}
          onClick={() => openCriteria(CRITERIAS.YEAR_BUILT)}
        />
      )}
      {searchData.saleDateLimitRange && (
        <Pill
          id='search-pill-dates'
          value={closedDate()}
          onClick={() => openCriteria(CRITERIAS.SALE_DATE_LIMIT_RANGE)}
        />
      )}
      {!isCanadaUser && searchData.totalSizeRange && searchData.totalSizeSet && (
        <Pill id='search-pill-total' value={totalSize()} />
      )}
    </div>
  );
};
