import styled from 'styled-components';

import { svgStringToDataUrl } from '../../../../../components/Slide/services/svgStringToDataUrl';
import { useWhiteLabelSettings } from '../../../../../features/whitelabel/useWhiteLabelSettings';
import coloredLayer from './assets/coloredLayer';
import banner from './assets/banner.svg';

export const WhiteLabelHeroImage = () => {
  const { primary } = useWhiteLabelSettings();

  const image = svgStringToDataUrl(coloredLayer, primary);

  return (
    <Wrapper>
      <BannerImage src={banner} alt='' />
      <ColoredLayerImage src={image} alt='' />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: block;
  max-width: 662px;

  @media (max-width: 980px) {
    display: none;
  }
`;

const BannerImage = styled.img`
  z-index: 2;
  position: relative;
`;

const ColoredLayerImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;
