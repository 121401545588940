import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { subjectAtom } from '../../state/subjectAtom';
import { CompetitionLegend } from './legend/CompetitionLegend';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { withHeight } from '../../../../pages/PresentationCreate/dash/ReportFacade/components/withHeight';
import { PropertyModal } from '../../components/PropertyModal/PropertyModal';
import { CompettionMap } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/CompResults/CompsPerformance/Competition/CompettionMap/CompettionMap';
import { useShowPropertyDetails } from '../../hooks/useShowPropertyDetails';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

const markerScaleSize = { x: 18, y: 25 };

function ActiveCompetitionChart({ height = 100, isPdf = false, className = '' }) {
  const subject = useAtomValue(subjectAtom);
  const properties = useAtomValue(reportPropertiesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const { showPropertyDetails, isPropertyModalOpen, setIsPropertyModelOpen } =
    useShowPropertyDetails();

  const activeProperties = useMemo(
    () => properties?.filter(p => p.status === 'active' && !p.excluded) || [],
    [properties],
  );

  const style = { height: `${height - 70}px`, width: `100%` };

  const onMoreInfo = (property: TProperty) => {
    showPropertyDetails(property);
  };

  const moreInfoButtonColor = useUiConfigColorsPrimary();

  return (
    <Container id='active-competition-frame' className={className}>
      <CompetitionLegend />

      <CompettionMap
        isPdf={isPdf}
        moreInfo={onMoreInfo}
        moreInfoButtonColor={moreInfoButtonColor}
        subject={subject}
        active={null}
        noModal={false}
        properties={activeProperties}
        style={style}
        markerScaleSize={markerScaleSize}
      />

      {isPresentationView && (
        <PropertyModal
          id='active-competition-frame'
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModelOpen(false)}
        />
      )}
    </Container>
  );
}

export default withHeight(ActiveCompetitionChart);

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
`;
