import React from 'react';
import { useIsDTC } from '../../../../../../../hooks/daysOnMarket/useIsDTC';
import { getMedianPaceDays } from '../../../../../../../services/getMedianPaceDays';

export function useSellingPaceTookTimeMessage(data) {
  const isDTC = useIsDTC();

  return React.useMemo(() => {
    const fieldToCheck = getMedianPaceDays(isDTC);
    const metricText = isDTC ? 'days to close' : 'days on market';

    if (!data) return '';

    if (data?.[fieldToCheck]) {
      return `Half of closed comparables took less than ${data[fieldToCheck]} ${metricText}`;
    }

    return !data?.average_of_all
      ? ''
      : `The average closed comparable took ${data?.average_of_all} days to close.`;
  }, [data, isDTC]);
}
