import React from 'react';

import iBuyerBg from './assets/iBuyer-bg.png';
import iBuyer from './assets/iBuyer.png';
import realtorBg from './assets/realtor-bg.png';
import realtor from './assets/realtor.png';

import {
  CardHeader,
  CardImageContainer,
  Image,
  CardHeaderTitle,
} from './CardHeaderTraditional.styles';

interface CardHeaderTraditionalProps {
  isIBuyer?: boolean;
}

export const CardHeaderTraditional: React.FC<CardHeaderTraditionalProps> = ({ isIBuyer }) => {
  return (
    <CardHeader
      style={{ backgroundImage: `url(${isIBuyer ? iBuyerBg : realtorBg})` }}
      isIBuyer={isIBuyer}
    >
      <CardImageContainer>
        <Image src={isIBuyer ? iBuyer : realtor} alt='Realtor VS iBuyer image' />
      </CardImageContainer>
      <CardHeaderTitle>{isIBuyer ? 'iBUYER' : 'REALTOR'}</CardHeaderTitle>
    </CardHeader>
  );
};
