import { RuntimeEnvironment } from '../../types';

export class Environment {
  static getEnv(): RuntimeEnvironment {
    return process.env.REACT_APP_ENV as RuntimeEnvironment;
  }

  static isDevelopment() {
    return process.env.REACT_APP_ENV === 'development';
  }

  static isStaging() {
    return process.env.REACT_APP_ENV === 'staging';
  }

  static isProduction() {
    return process.env.REACT_APP_ENV === 'production';
  }
}
