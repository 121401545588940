'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'Carousel', {
  enumerable: true,
  get: function get() {
    return _Carousel.default;
  },
});
Object.defineProperty(exports, 'Thumbs', {
  enumerable: true,
  get: function get() {
    return _Thumbs.default;
  },
});

var _Carousel = _interopRequireDefault(require('./components/Carousel'));

var _Thumbs = _interopRequireDefault(require('./components/Thumbs'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
