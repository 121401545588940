import { PropsWithChildren } from 'react';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';

import boldTrailTheme from './bold-trail';
import corePresentTheme from './core-present';
import { useSyncCSSVariables } from './utils';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';
import { useIsBoldTrailDomain } from '../../hooks/useIsBoldTrailDomain';
import { useDomainWhitelabelSettings } from '../../features/whitelabel/useDomainWhitelabelSettings';
import { getUpdatedWhiteLabelTheme } from './getUpdatedWhiteLabelTheme';

export function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const isBoldTrailDomain = useIsBoldTrailDomain();
  const { data: userSettings } = useDomainWhitelabelSettings();

  const theme = shouldUseBtTheme || isBoldTrailDomain ? boldTrailTheme : corePresentTheme;
  const updatedTheme = getUpdatedWhiteLabelTheme(theme, userSettings);
  useSyncCSSVariables(updatedTheme);

  return <ThemeProviderStyled theme={updatedTheme}>{children}</ThemeProviderStyled>;
}
