import React, { Dispatch, SetStateAction } from 'react';
import { TProperty } from '../../../../../../../../types';
import { MoneyFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import {
  TableItem,
  ImageWrapper,
  Image,
  PropertyDetailsContainer,
  Price,
  PropertyDetails,
  PropertyDetail,
  AddressContainer,
  Address,
  City,
  PropertyInfoWrapper,
} from './ListingFeedbackItem.styles';
import { RatingSlider } from './RatingSlider/RatingSlider.component';
import { getPropertyId } from '../../../../../../../../features/report/services/getPropertyId';

interface ListingFeedbackItemProps {
  property: TProperty;
  setPropertyRatings: Dispatch<SetStateAction<Record<string, number>>>;
  propertyRatings: Record<string, number>;
}

export const ListingFeedbackItem: React.FC<ListingFeedbackItemProps> = ({
  property,
  setPropertyRatings,
  propertyRatings,
}) => {
  return (
    <TableItem>
      <PropertyInfoWrapper>
        <ImageWrapper>
          <Image src={property?.images[0]} />
        </ImageWrapper>
        <PropertyDetailsContainer>
          <Price>{MoneyFormatter.format(property?.price)}</Price>
          <PropertyDetails>
            <PropertyDetail>{property?.beds} beds</PropertyDetail>
            <PropertyDetail>{property?.baths} baths</PropertyDetail>
            <PropertyDetail>{property?.size} SQFT</PropertyDetail>
          </PropertyDetails>
        </PropertyDetailsContainer>
      </PropertyInfoWrapper>
      <AddressContainer>
        <Address>{property?.address?.deliveryLine}</Address>
        <City>{property?.address?.city}</City>
      </AddressContainer>
      <RatingSlider
        propertyId={getPropertyId(property)}
        propertyAddress={`${property?.address?.deliveryLine}, ${property?.address?.city}`}
        setPropertyRatings={setPropertyRatings}
        propertyRatings={propertyRatings}
      />
    </TableItem>
  );
};
