import styled from 'styled-components';

export const CardContainer = styled('div')({
  padding: '5px 25px',
  borderBottom: '1px solid #DEDEDE ',
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  '&:last-of-type': {
    borderBottom: 'none',
  },
});

export const CardInfoContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const CardInfoWrapper = styled('div')({
  textAlign: 'start',
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const CardInfoName = styled('div')({
  fontSize: 11,
  color: '#000',
  fontWeight: 'bold',
  marginBottom: 2,
  marginTop: 2,
});

export const CardInfoValue = styled('div')({
  fontSize: 12,
  color: '#000',
  lineHeight: 2.1,
});

export const PixelatedContainer = styled('div')({
  display: 'flex',
});

export const PixelatedImage = styled('div')({
  backgroundImage: 'url(/present/assets/images/pixelated.png)',
  backgroundSize: 'contain',
});

export const PhonePixelatedImage = styled(PixelatedImage)({
  width: 34,
});

export const EmailPixelatedImage = styled(PixelatedImage)({
  width: 59,
});
