import styled from 'styled-components';

import { useSellingPaceUI } from '../../../hooks/useSellingPaceUI';
import { replaceFillColors } from '../../../services/replaceFillColors';

interface SellingPaceCardContentProps {
  data: any;
  color: string;
}

const SellingPaceCardContent = ({ data, color }: SellingPaceCardContentProps) => {
  const { image } = useSellingPaceUI(data);

  const imageUrl = replaceFillColors(image, color);

  return <Image src={imageUrl} />;
};

export default SellingPaceCardContent;

const Image = styled.img`
  height: 90px;
`;
