import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { trackElementClick } from '../../../../../pages/PresentationCreate/dash/communicators/tracking';
import { getDefaultHighlightsAndThumbsUp } from '../../../../../pages/PresentationCreate/dash/store/result/operations';
import { TRACKING_EVENTS } from '../../../../../services/TrackingEvent';

import { TProperty } from '../../../../../types';
import { useShowPropertyDetails } from '../../../hooks/useShowPropertyDetails';
import { pricePerSqftModeAtom } from '../../../state/pricePerSqftModeAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import { excludedPropertyIdsAtom } from '../../../state/excludedPropertyIdsAtom';
import { customMarkersAtom } from '../../../state/customMarkersAtom';
import { selectedCategoriesAtom } from '../../../state/selectedCategoriesAtom';
import { customEstimatesAtom } from '../../../state/customEstimatesAtom';
import { isPresentationViewAtom } from '../../../state/isPresentationViewAtom';
import { extractPropertyGroups } from '../../../services/extractPropertiesGroups';
import { RangeMarker } from './RangeMarker';
import { sortScaleProperties } from '../services/sortScaleProperties';
import { buildContainerStyles } from '../services/buildContainerStyles';
import { calculateMarkerHeight, calculatePdfHeight } from '../services/calculateHeight';
import { calculateOffset } from '../services/offsetCalculator';
import { getPropertyAdjustedPrice } from '../services/getPropertyAdjustedPrice';
import { useHighlightedProperties } from '../../../state/highlighted-properties/useHighlightedProperties';
import { isAdjustedPriceModeAtom } from '../../../state/isAdjustedPriceModeAtom';
import { getPropertyId } from '../../../services/getPropertyId';

interface RangeHighlightsProps {
  overrideMode?: boolean;
  agentInfo?: any;
  defaultMarkersOnly?: boolean;
  isPdf?: boolean;
  overridePlotBy?: boolean;
}

export const RangeHighlights = ({
  overrideMode,
  agentInfo = null,
  defaultMarkersOnly = false,
  isPdf = false,
  overridePlotBy,
}: RangeHighlightsProps) => {
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);
  const propertiesAtom = useAtomValue(reportPropertiesAtom);
  const excluded = useAtomValue(excludedPropertyIdsAtom);
  const customMarkers = useAtomValue(customMarkersAtom);
  const selectedCategories = useAtomValue(selectedCategoriesAtom);
  const customEstimates = useAtomValue(customEstimatesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const isAdjustedPrice = useAtomValue(isAdjustedPriceModeAtom);

  const { showPropertyDetails } = useShowPropertyDetails();

  const properties = extractPropertyGroups(propertiesAtom);
  const highlighted = useHighlightedProperties();

  const highlights = [...highlighted, ...customMarkers];
  const categories = [...selectedCategories, ...customEstimates];

  const markerBaseHeight = !isPdf ? 45 : 65;
  const allowTracking = !isPdf && isPresentationView;

  const numberOfSelected = highlights.length + categories.length;

  const highlightsToUse = defaultMarkersOnly
    ? getDefaultHighlightsAndThumbsUp(properties, { excluded }).highlights
    : highlights;

  const pricePerSqftModeToUse = isPdf ? Boolean(overrideMode) : pricePerSqftMode;
  const useAdjPrice = isPdf ? Boolean(overridePlotBy) : isAdjustedPrice;

  const { sortedCategories, sortedHighlights } = sortScaleProperties({
    categories,
    highlights: highlightsToUse,
    useSqft: pricePerSqftModeToUse,
    defaultsOnly: false,
    useAdjustedPrice: useAdjPrice,
  });

  const onMarkerClick = (clickedProperty: any) => {
    if (!clickedProperty.status || isPdf) {
      return;
    }

    const property = propertiesAtom
      .map(property => property.property)
      .find(p => getPropertyId(p) === clickedProperty.id);
    showPropertyDetails(property as TProperty);
    allowTracking &&
      // @ts-ignore
      trackElementClick(property?.address?.deliveryLine, TRACKING_EVENTS.COMPS_ELEMENT_CLICKED);
  };

  const largestCost = Math.max(
    ...sortedHighlights.map(h => (pricePerSqftModeToUse ? h.pricePerSqft : h.price)),
  );

  const containerStyles = buildContainerStyles(
    sortedHighlights,
    numberOfSelected,
    markerBaseHeight,
    isPdf,
  );

  return (
    <RangeHighlightsWrapper isCentered={numberOfSelected < 2} isPdf={isPdf}>
      <div style={{ position: 'relative', ...containerStyles }}>
        {sortedHighlights.map((property, index) => {
          const { id, name, price, priceFrom, priceTo, pricePerSqft, status } = property;

          const cost = pricePerSqftModeToUse ? pricePerSqft : price;
          const height = !isPdf
            ? calculateMarkerHeight(index, markerBaseHeight)
            : calculatePdfHeight(numberOfSelected, markerBaseHeight, index);

          const adjusted = getPropertyAdjustedPrice({
            id,
            properties: propertiesAtom,
            useSqftPrice: pricePerSqftModeToUse,
          });

          const offsetCalculationPrice = useAdjPrice ? adjusted || cost : cost;

          return (
            <RangeMarker
              key={`${id}_${index}`}
              id={status}
              text={name}
              height={height}
              offsetLeft={calculateOffset(
                index,
                sortedCategories,
                sortedHighlights,
                offsetCalculationPrice,
                numberOfSelected,
                pricePerSqftModeToUse,
                useAdjPrice,
              )}
              price={cost}
              priceFrom={priceFrom}
              priceTo={priceTo}
              property={property}
              onClick={() => onMarkerClick(property)}
              agentInfo={agentInfo}
              isLastMarker={largestCost === cost}
              isPdf={isPdf}
              adjustedPrice={adjusted}
              overridePlotBy={overridePlotBy}
            />
          );
        })}
      </div>
    </RangeHighlightsWrapper>
  );
};

const RangeHighlightsWrapper = styled.div<{ isCentered: boolean; isPdf?: boolean }>`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  position: relative;
  top: ${({ isPdf }) => (isPdf ? '12px' : '15px')};
  padding: ${({ isPdf }) => (isPdf ? '0 90px 0 220px' : '0 180px 0 260px')};

  ${({ isCentered }) =>
    isCentered &&
    `display: flex;
    justify-content: center;`}
`;
