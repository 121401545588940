type TLockedStatus = Record<string, boolean | undefined>;
type TEntityId = number | null;

export function getConfigLockedStatus(
  entity: TEntityId,
  primaryEntity: number,
  lockedStatus?: TLockedStatus,
): boolean | undefined {
  if (!lockedStatus) return undefined;

  if (entity === -2) return lockedStatus['e--2'] ?? undefined;

  if (entity === -1) return lockedStatus['e--1'] ?? undefined;

  if (entity === 0) return lockedStatus['e--1'] || lockedStatus['e-0'] || undefined;

  const entityId = entity ?? primaryEntity;

  return (
    lockedStatus['e--1'] ||
    lockedStatus['e--2'] ||
    lockedStatus['e-0'] ||
    lockedStatus[`e-${entityId}`] ||
    undefined
  );
}
