import { useIsDTC } from './daysOnMarket/useIsDTC';
import { getMedianPaceDays } from '../services/getMedianPaceDays';
import high from '../assets/images/dashboard/homeinfo/high';
import medium from '../assets/images/dashboard/homeinfo/medium';
import low from '../assets/images/dashboard/homeinfo/low';

interface PaceRange {
  max: number;
  image: string;
  text: string;
}

const paceRanges: PaceRange[] = [
  { max: 15, image: high, text: 'High' },
  { max: 30, image: medium, text: 'Average' },
  { max: 45, image: low, text: 'Low' },
];

export const useSellingPaceUI = (data: any) => {
  const isDTC = useIsDTC();
  const medianPaceDays = getMedianPaceDays(isDTC);

  let image = low;
  let text = 'Low';

  if (data && data?.[medianPaceDays] !== undefined) {
    for (const range of paceRanges) {
      if (data[medianPaceDays] < range.max) {
        image = range.image;
        text = range.text;
        break;
      }
    }
  }

  return {
    image,
    text,
  };
};
