import styles from './CommonStyles.module.scss';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { InfoCard } from '../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import { useAtomValue } from 'jotai';
import { pricingRateOfChangeAtom } from '../../../../../../features/report/state/pricingRateOfChangeAtom';

export const PricingRateOfChangeGauge = ({ onClick = f => f, oneSheeterSlide = false }) => {
  const color = useUiConfigColorsPrimary();
  const data = useAtomValue(pricingRateOfChangeAtom);

  return (
    <InfoCard
      headline='Pricing Rate of Change'
      sup='%'
      content={_ => data.text}
      description='Average rate of change between listed and recorded price of sold comps'
      background='/present/assets/images/dashboard/homeinfo/pricing-bg.png'
      info='This measurement tracks both the listing and closed price for all closed comparables and compares to establish if closed comparables are selling above, below or equal to the listing price.'
      clicked={onClick}
      borderedCircleColor={color}
      className={styles.card}
      oneSheeterSlide={oneSheeterSlide}
      disabled={!data?.props?.length}
    />
  );
};
