import React from 'react';
import PropTypes from 'prop-types';

import classes from './BorderedCircle.module.scss';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';

const BorderedCircle = ({
  boxSize,
  borderSize,
  children,
  sup,
  sub,
  className,
  grayBorder,
  numberColor,
  print,
  style = {},
  hoveredColor,
}) => {
  const color = useUiConfigColorsPrimary();
  const border = 2 * parseInt(borderSize);
  const borderPx = `${border}px`;
  const grayBorderClass = grayBorder ? classes.grayBorder : '';

  let subStyles = {};
  let circleStyles = { width: boxSize, height: boxSize };
  if (numberColor) {
    subStyles = { color: numberColor };
    circleStyles = { ...circleStyles, background: numberColor };
  }
  const circleStylesPrint = {
    backgroundImage: 'none',
    backgroundColor: color,
  };

  const supSubStylesPrint = {
    color,
  };

  return (
    <div className={`${classes.borderedCircle} ${className}`} style={style}>
      <div
        className={`${classes.parent} ${grayBorderClass}`}
        style={!print ? circleStyles : { ...circleStyles, ...circleStylesPrint }}
      >
        <div
          className={classes.inner}
          style={{
            width: `calc(100% - ${borderPx})`,
            height: `calc(100% - ${borderPx})`,
            ...(hoveredColor && {
              backgroundColor: hoveredColor,
            }),
          }}
        >
          {children}
        </div>
      </div>
      {sup && (
        <span className={classes.sup} style={print ? supSubStylesPrint : subStyles}>
          {sup}
        </span>
      )}
      {sub && (
        <span className={classes.sub} style={print ? supSubStylesPrint : subStyles}>
          {sub}
        </span>
      )}
    </div>
  );
};

BorderedCircle.propTypes = {
  boxSize: PropTypes.string,
  borderSize: PropTypes.string,
  className: PropTypes.string,
  sup: PropTypes.string,
  sub: PropTypes.string,
  children: PropTypes.any,
  grayBorder: PropTypes.bool,
  print: PropTypes.bool,
};

BorderedCircle.defaultProps = {
  boxSize: '290px',
  borderSize: '8px',
  sup: '',
  sub: '',
  children: null,
  className: '',
  grayBorder: false,
  print: false,
};

export { BorderedCircle };
