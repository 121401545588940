import { useMutation } from '@tanstack/react-query';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useDispatch, useStore } from 'react-redux';
import { useUpdatePresentation } from '../../../hooks/useUpdatePresentation';
import { resultOperations } from '../../../pages/PresentationCreate/dash/store/result';

export function useUpdatePresentationWithBuild() {
  const { data: presentation } = usePresentationFromParams();
  const { mutateAsync: updatePresentation } = useUpdatePresentation(presentation?.hash);

  const dispatch = useDispatch();
  const getState = useStore().getState;

  return useMutation(async () => {
    await resultOperations.refreshBuild(presentation?.type, presentation?.id)(dispatch, getState);

    if (!presentation?.id) return;

    const reportData = getState();

    await updatePresentation({
      ...presentation,
      reportData,
    });
  });
}
