import styled from 'styled-components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resultOperations } from '../../../store/result';
import { PropertyModal } from '../../../../../../features/report/components/PropertyModal/PropertyModal';
import { useAtom, useAtomValue } from 'jotai';
import { isPropertyModalOpenAtom } from '../../../../../../features/report/state/isPropertyModalOpenAtom';
import { ScaleOverviewPresentation } from '../../../../../../features/report/scale-overview/ScaleOverviewPresentation';
import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
`;

const Scale = () => {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);
  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const properties = propertiesRaw.map(prop => prop.property);

  const dispatch = useDispatch();
  const setExcluded = a => dispatch(resultOperations.setExcluded(a));

  useEffect(() => {
    window.scrollTo(0, 0);
    const excludedIds = properties.filter(p => p.excluded).map(p => p.id);
    setExcluded(excludedIds);
  }, []);

  return (
    <Container id='scale-frame'>
      <ScaleOverviewPresentation />
      <PropertyModal
        isModalOpen={isPropertyModalOpen}
        onCloseModal={() => setIsPropertyModalOpen(false)}
      />
    </Container>
  );
};

export default Scale;
