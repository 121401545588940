import React from 'react';

import { pricingFavorabilityData } from '../data';
import { SlideWithGraphWrapper } from '../../../../../components/common/SlideWithGraphWrapper';
import { TCategory } from '../../../../../../../../types';
import { PricingFavorabilityChart } from '../../../../../../../../features/report/charts/pricing-favorability/PricingFavorabilityChart';

interface PricingFavorabilityProps {
  category: TCategory;
}

// This is the slide gauge popup
const PricingFavorability: React.FC<PricingFavorabilityProps> = ({ category }) => (
  <SlideWithGraphWrapper
    description={pricingFavorabilityData.description}
    heading={pricingFavorabilityData.heading}
    graphPadding={'3.5%'}
    category={category}
  >
    <PricingFavorabilityChart />
  </SlideWithGraphWrapper>
);

export default PricingFavorability;
