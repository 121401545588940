import { CSSProperties } from 'styled-components';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';
import { ReactComponent as Logo } from '../../assets/images/present_logo.svg';
import { Icon } from '../Common';
import { useIsBoldTrailDomain } from '../../hooks/useIsBoldTrailDomain';
import { svgStringToDataUrl } from '../Slide/services/svgStringToDataUrl';
import presentLogoWL from './presentLogoWL';
import { useWhiteLabelSettings } from '../../features/whitelabel/useWhiteLabelSettings';

interface Props {
  isNewLogo?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const PresentLogo = ({ isNewLogo, style, onClick }: Props) => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const isBoldTrailDomain = useIsBoldTrailDomain();

  const { hasWL, primaryLogoColor, secondaryLogoColor } = useWhiteLabelSettings();

  if (hasWL) {
    const logo = svgStringToDataUrl(presentLogoWL, primaryLogoColor, secondaryLogoColor);

    return <img src={logo} style={{ marginRight: '10%' }} onClick={onClick} />;
  }

  if (shouldUseBtTheme || isBoldTrailDomain) {
    return (
      <Logo
        onClick={onClick}
        style={{ cursor: typeof onClick === 'function' ? 'pointer' : 'auto' }}
      />
    );
  }

  if (isNewLogo) {
    return <Icon iconName='logo_new' style={style} onClick={onClick} />;
  }

  return <Icon iconName='logo' style={style} onClick={onClick} />;
};
