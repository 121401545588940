import { RawReport, TSellingPace } from '../../../types';

export function extractSellingPace(report: RawReport): TSellingPace {
  const rawData = report?.graphs?.selling_pace || {};

  if (!rawData) return {} as TSellingPace;

  const graph =
    rawData.graph.map(({ id, address, days_on_market, days_to_close, sale_date, sale_price }) => ({
      id,
      days_on_market,
      days_to_close,
      sale_date,
      sale_price,
      name: address,
    })) || [];

  return {
    ...rawData,
    graph,
  };
}
