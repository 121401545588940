import { combineReducers } from 'redux';

import { search, result, report } from '../pages/PresentationCreate/dash/store/reducers';

const appReducers = combineReducers({
  search,
  result,
  report,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'GLOBAL_STORE_SET') {
    state = action.payload;
  }

  return appReducers(state, action);
};

export default rootReducer;
