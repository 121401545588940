import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Card.module.scss';

const Card = ({
  headline,
  centered,
  hoverable,
  borders,
  shadow,
  children,
  className,
  style,
  clicked,
  info,
  infoColor = '#6ab4e4',
  headerStyles = {},
}) => {
  const cardClasses =
    `${classes.card} ${centered && classes.centered} ${hoverable && classes.hoverable}` +
    ` ${borders && classes.borders} ${shadow && classes.shadow} ${className}`;

  return (
    <div className={cardClasses} style={style} onClick={clicked} data-test-card={true}>
      {/*{info && (*/}
      {/*  <span*/}
      {/*    data-tip={info}*/}
      {/*    className={classes.info}*/}
      {/*    style={{ color: infoColor }}*/}
      {/*    data-test-card-info={true}*/}
      {/*  >*/}
      {/*    <FontAwesomeIcon style={{ opacity: '0.5' }} icon='info-circle' />*/}
      {/*    <ReactTooltip effect='solid' event='click' className={classes.tooltip} />*/}
      {/*  </span>*/}
      {/*)}*/}

      {headline && (
        <header className={classes.header} style={headerStyles}>
          {headline}
        </header>
      )}
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string,
  className: PropTypes.string,
  centered: PropTypes.bool,
  borders: PropTypes.bool,
  shadow: PropTypes.bool,
  style: PropTypes.any,
  clicked: PropTypes.func,
  info: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  headline: '',
  className: '',
  centered: false,
  borders: true,
  shadow: false,
  style: {},
  clicked: f => f,
  info: '',
};

export { Card };
