import { ShelfLifeGauge } from './ShelfLifeGauge';
import { CompetitionGauge } from './CompetitionGauge';
import { PricePerSqftGauge } from './PricePerSqftGauge';
import { PricingRateOfChangeGauge } from './PricingRateOfChangeGauge';
import { SellingPaceGauge } from './SellingPaceGauge/SellingPaceGauge';
import { PricingFavorabilityGauge } from './PricingFavorabilityGauge/PricingFavorabilityGauge';

export const CompsPerformance = {
  PricingFavorabilityGauge,
  CompetitionGauge,
  SellingPaceGauge,
  PricingRateOfChangeGauge,
  PricePerSqftGauge,
  ShelfLifeGauge,
};
