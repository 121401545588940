import React, { Dispatch, SetStateAction } from 'react';
import { useUser } from '../../../../../providers/UserProvider';
import { EditIcon } from '../components/EditIcon/EditIcon.component';
import { useIsEditAllowed } from '../hooks/useIsEditAllowed';
import { PropertyFeature, PropertyFeaturesContainer } from './LuxuryCover.styles';

interface PropertyFeaturesProps {
  displayImage?: boolean;
  beds: number;
  size: number;
  setIsEditFeatures: Dispatch<SetStateAction<boolean>>;
}

export const PropertyFeatures: React.FC<PropertyFeaturesProps> = ({
  displayImage,
  beds,
  size,
  setIsEditFeatures,
}) => {
  const user = useUser();
  const isCanadaUser = user?.isCanadaUser;

  const isEditAllowed = useIsEditAllowed();

  return (
    <PropertyFeaturesContainer displayImage={displayImage}>
      {!isNaN(beds) && (
        <PropertyFeature displayImage={displayImage}>{beds} Bedrooms </PropertyFeature>
      )}
      {!isCanadaUser && size && (
        <PropertyFeature displayImage={displayImage}>{size} SQFT Living Area</PropertyFeature>
      )}
      {isEditAllowed && <EditIcon onClick={() => setIsEditFeatures(true)} />}
    </PropertyFeaturesContainer>
  );
};
