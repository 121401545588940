import React from 'react';
import { useAtomValue } from 'jotai';

import { reportPropertiesAtom } from '../features/report/state/reportPropertiesAtom';
import { extractPropertyGroups } from '../features/report/services/extractPropertiesGroups';

export const withProperties = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
  return function WithPropertiesWrapper(props: T) {
    const propertiesRaw = useAtomValue(reportPropertiesAtom);
    const propertyGroups = extractPropertyGroups(propertiesRaw);

    return <WrappedComponent properties={propertyGroups} {...props} />;
  };
};
