import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './CompResults.module.scss';
import { CompsPerformance } from './CompsPerformance/CompsPerformance';
import { useIsReportInStore, usePresentation } from '../../../../../../../hooks/usePresentation';
import { useSetPresentationHash } from '../../../../../../../state/presentationHash/useSetPresentationHash';
import { PropertyModal } from '../../../../../../../features/report/components/PropertyModal/PropertyModal';
import { useAtom } from 'jotai';
import { isPropertyModalOpenAtom } from '../../../../../../../features/report/state/isPropertyModalOpenAtom';
import { useSyncStateWithRedux } from '../../../../../../../features/report/hooks/useSyncStateWithRedux';
import { ResultsHeader } from '../../../../../../../features/report/results-header/ResultsHeader';
import { ScaleOverviewReport } from '../../../../../../../features/report/scale-overview/ScaleOverviewReport';

export const CompResultsPresentation = () => {
  const { presentationId } = useParams();
  const { isLoading, data } = usePresentation(presentationId);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);

  useSetPresentationHash(presentationId);
  const isReportInStore = useIsReportInStore();

  useSyncStateWithRedux();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading || !isReportInStore || !data) return null;

  return (
    <>
      <ResultsHeader />
      <div className={styles.compResults}>
        <ScaleOverviewReport />
        <CompsPerformance />

        <PropertyModal
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      </div>
    </>
  );
};
