import styled from 'styled-components';
import { ComparableExtension } from '../../../../../types';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/trash_filled.svg';
import { useAtomValue } from 'jotai';
import { isPresentationViewAtom } from '../../../../../features/report/state/isPresentationViewAtom';
import { Label } from '../../components/Label';
import { Value } from '../../components/Value';
import { useComparableExtensionDelete } from './useComparableExtensionDelete';

interface Props {
  propertyId: string;
  extension: ComparableExtension;
  hideActions?: boolean;
}

export const ExtensionItem = ({ propertyId, extension, hideActions }: Props) => {
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const { mutate, isLoading } = useComparableExtensionDelete();

  const plusSign = extension.value > 0 ? '+' : '';

  return (
    <Container
      style={{ marginBottom: isPresentationView || (!isPresentationView && hideActions) ? 10 : 0 }}
    >
      <Label>{extension.name}</Label>
      <PriceContainer>
        <Value>
          {plusSign}
          {MoneyFormatter.format(extension.value)}
        </Value>
        {!isPresentationView && !hideActions && (
          <DeleteIconWrapper
            onClick={() => {
              if (isLoading) return;
              mutate({ propertyId, extensionId: extension.id });
            }}
          >
            <DeleteIcon />
          </DeleteIconWrapper>
        )}
      </PriceContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DeleteIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
