import React, { useState } from 'react';

import { Button } from '../../components/Common';
import styled from 'styled-components';
import { Container, Copyright, Wrapper } from '../../layouts/GuestLayout/GuestLayout.styles';
import { Header } from '../../layouts/GuestLayout/Header/Header.component';
import { Input } from '../PresentationCreate/dash/view/components/Input/Input';
import styles from '../PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyAddress/PropertyInfoOverride/PropertyInfoOverride.module.scss';
import { corePresentApi } from '../../api/CorePresentApi';
import { CheckboxWithLabel } from '../PresentationCreate/dash/view/components/CheckboxWithLabel/CheckboxWithLabel';
import { SingleListing } from './SingleListing';
const PageContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.colors.gray[100],
  maxWidth: `100vw`,
  position: 'relative',
}));
const SearchBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.colors.gray[200],
  padding: '30px',
}));
const SearchInputs = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
});
const Results = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
});
const ResultsHeader = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  padding: '30px 100px',
});
const UserScope = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '33%',
});
const ResultsHeaderTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '33%',
});
const DebugModeCheckbox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '33%',
});
const ResultsGrid = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '30px',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  flex: 1,
});

export const FeedCheckPage: React.FC = () => {
  console.log('running version:' + process.env.REACT_APP_TAG);
  const [mlsId, setMlsId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isDebugMode, setIsDebugMode] = useState(true);

  const checkMlsId = async () => {
    setIsLoading(true);
    setListings([]);
    setUserDetails(null);
    const data = await corePresentApi.get(
      `/address/check-mls-id-in-feed?mls_id=${mlsId}&user_email=${userEmail}`,
    );
    setListings(data.listings);
    setUserDetails(data.user);
    setIsLoading(false);
  };

  return (
    <Container>
      <Wrapper>
        <Header text={'Feed Checker'} />
        <PageContainer>
          <SearchBox>
            <h3>Search Criteria</h3>
            <SearchInputs>
              <Input
                label='MLS ID'
                value={mlsId}
                placeholder='Enter the MLS ID'
                onChange={setMlsId}
                className={`${styles.col3} ${styles.spacing}`}
              />
              <Input
                label='User Email'
                value={userEmail}
                placeholder='Optional: If you enter a user email, the results will be
                scoped by that user.'
                onChange={setUserEmail}
                className={`${styles.col3} ${styles.spacing}`}
              />
            </SearchInputs>
            <Button
              disabled={!mlsId}
              onClick={checkMlsId}
              btnstyle='submit'
              size='large'
              loading={isLoading}
            >
              Check MLS ID
            </Button>
          </SearchBox>
          <Results>
            <ResultsHeader>
              {!userDetails ? (
                <UserScope>Results not scoped by user.</UserScope>
              ) : (
                <UserScope>
                  The results of this query have been scoped for user: <br />
                  User ID / Account ID: {userDetails.kvcore_user_id} /{' '}
                  {userDetails.kvcore_account_id} <br />
                  Name: {userDetails.first_name} {userDetails.last_name} <br />
                  Canada User: {userDetails.is_canada_user ? 'Yes' : 'No'} <br />
                  Feeds: <span style={{ wordBreak: 'break-word' }}>{userDetails.feed_ids}</span>
                </UserScope>
              )}
              <ResultsHeaderTitle>
                <h3>Search Results</h3>
                <small>{listings.length} results found.</small>
              </ResultsHeaderTitle>
              <DebugModeCheckbox>
                <CheckboxWithLabel
                  boldChecked
                  label='Debug Mode'
                  value={isDebugMode}
                  style={{ marginTop: '-15px' }}
                  onChange={() => setIsDebugMode(!isDebugMode)}
                />
              </DebugModeCheckbox>
            </ResultsHeader>
            <ResultsGrid>
              {listings.map((listing: any) => {
                return (
                  <SingleListing listing={listing} debugMode={isDebugMode} key={listing.mlsid} />
                );
              })}
            </ResultsGrid>
          </Results>

          <Copyright>
            Copyright © {new Date().getFullYear()} InsideRE, LLC. All rights reserved.
          </Copyright>
        </PageContainer>
      </Wrapper>
    </Container>
  );
};
