import { getPropertyId } from './getPropertyId';
import { TProperty, PropertyGroups, THighPropertyLight } from '../../../types';

export const extractHighlightedProperties = (
  properties: PropertyGroups,
  highlighted: THighPropertyLight[],
): TProperty[] => {
  const { active, pending, sold, comingSoon } = properties;

  return [...(active ?? []), ...(pending ?? []), ...(sold ?? []), ...(comingSoon ?? [])]
    .filter(property =>
      highlighted.find(highlightedProperty => highlightedProperty.id === getPropertyId(property)),
    )
    .sort((a, b) => (a.salePrice || a.price) - (b.salePrice || b.price));
};
