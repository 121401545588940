import { TCompConfigItem } from '../../../../types';

enum ESTIMATES_KEYS {
  ATTOM = 'ATTOM',
}

export const ESTIMATES: Record<ESTIMATES_KEYS, TCompConfigItem> = Object.freeze({
  [ESTIMATES_KEYS.ATTOM]: {
    label: '',
    labelHtml: 'Attom ESTIMATE',
    id: 'attom',
    idPerSqft: 'attom_per_sqft',
    customizedData: null,
    icon: '/present/assets/images/dashboard/categories/public-estimates.png',
  },
});
