import React from 'react';
import styled from 'styled-components';

import { TProperty } from '../../../../../../../../../../../types';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
`;

interface Props {
  property: TProperty;
}
export const Picture: React.FC<Props> = ({ property }) => (
  <Container>
    <Image src={property?.images?.[0] || '/present/assets/images/no-property-image.png'} alt='' />
  </Container>
);
