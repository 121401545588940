import { ESTIMATES } from '../estimates/estimatesConfig';
import {
  LARGER_FLAGS_BEDROOMS,
  LARGER_FLAGS_FOOTAGE,
  SMALLER_FLAGS_BEDROOMS,
  SMALLER_FLAGS_FOOTAGE,
} from '../outliers/flagsConfig';
import { ACTIVE, CLOSED, COMING_SOON, PENDING } from '../trends/trendsConfig';
import { FEATURES } from '../features/featuresConfig';

export const getCompsConfigAsCollection = () => [
  ...Object.values(ESTIMATES),
  ...Object.values(FEATURES),
  ...Object.values(ACTIVE),
  ...Object.values(PENDING),
  ...Object.values(CLOSED),
  ...Object.values(COMING_SOON),
  ...Object.values(LARGER_FLAGS_FOOTAGE),
  ...Object.values(LARGER_FLAGS_BEDROOMS),
  ...Object.values(SMALLER_FLAGS_BEDROOMS),
  ...Object.values(SMALLER_FLAGS_FOOTAGE),
];
