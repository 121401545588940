import Color from 'color';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';

import { getDefaultHighlightsAndThumbsUp } from '../../../../../pages/PresentationCreate/dash/store/result/operations';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';

import { extractPropertyGroups } from '../../../services/extractPropertiesGroups';
import { pricePerSqftModeAtom } from '../../../state/pricePerSqftModeAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import { excludedPropertyIdsAtom } from '../../../state/excludedPropertyIdsAtom';
import { customMarkersAtom } from '../../../state/customMarkersAtom';
import { selectedCategoriesAtom } from '../../../state/selectedCategoriesAtom';
import { customEstimatesAtom } from '../../../state/customEstimatesAtom';
import { isPresentationViewAtom } from '../../../state/isPresentationViewAtom';
import { isAdjustedPriceModeAtom } from '../../../state/isAdjustedPriceModeAtom';
import { getPropertyAdjustedPrice } from '../services/getPropertyAdjustedPrice';
import { useHighlightedProperties } from '../../../state/highlighted-properties/useHighlightedProperties';

const stylesPrint = (desiredColor: string) => {
  let color = Color('#000000');

  try {
    color = Color(desiredColor);
  } catch (e) {
    console.log(`Color (${desiredColor}) is not valid, using fallback color!`);
    color = Color('#000000');
  }
  const calculatedColor = Color(color).mix(Color('#ffffff'), 0.317).hex();

  return {
    background: `linear-gradient(90deg, ${calculatedColor} 0%, ${color} 100%)`,
  };
};

interface ScaleMiddleLineProps {
  overrideMode?: boolean;
  defaultMarkersOnly?: boolean;
  isPdf?: boolean;
  overridePlotBy?: boolean;
}

export const ScaleMiddleLine = ({
  overrideMode = false,
  defaultMarkersOnly = false,
  isPdf = false,
  overridePlotBy = false,
}: ScaleMiddleLineProps) => {
  const primaryColor = useUiConfigColorsPrimary();
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);
  const propertiesAtom = useAtomValue(reportPropertiesAtom);
  const excluded = useAtomValue(excludedPropertyIdsAtom);
  const customMarkers = useAtomValue(customMarkersAtom);
  const selectedCategories = useAtomValue(selectedCategoriesAtom);
  const customEstimates = useAtomValue(customEstimatesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const isAdjustedPriceMode = useAtomValue(isAdjustedPriceModeAtom);

  const useAdjPrice = isPdf ? Boolean(overridePlotBy) : isAdjustedPriceMode;

  const properties = extractPropertyGroups(propertiesAtom);
  const highlighted = useHighlightedProperties();

  const { colors } = useTheme();

  const highlights = [...highlighted, ...customMarkers];
  const categories = useMemo(
    () => [...selectedCategories, ...customEstimates],
    [customEstimates, selectedCategories],
  );

  const highlightsToUse = defaultMarkersOnly
    ? getDefaultHighlightsAndThumbsUp(properties, { excluded }).highlights
    : highlights;

  const pricePerSqftModeToUse = isPdf ? Boolean(overrideMode) : pricePerSqftMode;

  const prices = useMemo(() => {
    const allMarkers = [...highlightsToUse, ...categories];

    if (pricePerSqftModeToUse) {
      return allMarkers?.map(p => {
        if (useAdjPrice) {
          const adjustedPricePerSqft = getPropertyAdjustedPrice({
            id: p.id,
            properties: propertiesAtom,
            useSqftPrice: true,
          });

          return adjustedPricePerSqft || p.valuePerSqft || p.pricePerSqft;
        }

        return p.valuePerSqft || p.pricePerSqft;
      });
    }

    return allMarkers?.map(p => {
      if (useAdjPrice) {
        return p?.adjustedPrice || p.value || p.price;
      }

      return p.value || p.price;
    });
  }, [categories, highlightsToUse, pricePerSqftModeToUse, propertiesAtom, useAdjPrice]);

  if (highlightsToUse.length + categories.length < 2) {
    return null;
  }
  const colorToUse = !isPresentationView ? colors.v2.primary : primaryColor;

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const averagePrice = Math.floor((minPrice + maxPrice) / 2);
  const lowAveragePrice = Math.floor((minPrice + averagePrice) / 2);
  const highAveragePrice = Math.floor((averagePrice + maxPrice) / 2);

  return (
    <Wrapper style={stylesPrint(colorToUse)} isPdf={isPdf}>
      <Container isPdf={isPdf} isCreate={!isPresentationView}>
        <div>{MoneyFormatter.format(minPrice)}</div>
        <div>{MoneyFormatter.format(lowAveragePrice)}</div>
        <div>{MoneyFormatter.format(averagePrice)}</div>
        <div>{MoneyFormatter.format(highAveragePrice)}</div>
        <div>{MoneyFormatter.format(maxPrice)}</div>
      </Container>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ isPdf?: boolean }>`
  width: 100%;
  padding: ${({ isPdf }) => (isPdf ? '0' : '0 50px')};
`;

export const Container = styled.div<{ isPdf?: boolean; isCreate?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-weight: bold;
  margin: ${({ isPdf }) => (isPdf ? '0 auto' : '0')};
  padding: ${({ isPdf, isCreate }) =>
    isPdf ? '0 45px 0 180px' : isCreate ? '0 90px 0 260px' : '0 140px 0 260px'};
  height: ${({ isPdf }) => (isPdf ? '55px' : '60px')};
  width: ${({ isPdf }) => (isPdf ? '90%' : '100%')};
  font-size: ${({ isPdf }) => (isPdf ? '18px' : '16px')};
`;
