import { REDIRECT_TO_PARAM } from '../../constants/redirectToParam';
import { ROUTING_PREFIX } from '../../constants/routingPrefix';
import { Environment } from '../../services/DevelopmentUtilities/Environment';
import { StorageService } from '../../services/storageService';

export function constructWhitelabelLoginUrl(website: string) {
  const token = StorageService.read('token');
  const query: any = new URLSearchParams(location.search);

  const redirectTo = query.get(REDIRECT_TO_PARAM);

  const redirectToParam = redirectTo ? `&${REDIRECT_TO_PARAM}=${redirectTo}` : '';

  return Environment.isDevelopment()
    ? `http://${website}:3000${ROUTING_PREFIX}/whitelabel-login?token=${token}${redirectToParam}`
    : `https://${website}${ROUTING_PREFIX}/whitelabel-login?token=${token}${redirectToParam}`;
}
