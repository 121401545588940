import { MoneyFormatter } from '../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { TProperty } from '../../../../../../types';
import { NumberFormatter } from '../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { DefaultTheme } from 'styled-components';

export const getInfoWindowContent = ({
  property,
  isPartialMatch,
  isCanadaUser,
  subject,
  theme,
}: {
  property: TProperty;
  subject: TProperty;
  isPartialMatch?: boolean;
  isCanadaUser?: boolean;
  theme: DefaultTheme;
}) => {
  const calculatePercentDiff = () => {
    if (!subject) return;

    const percentDiff = (property.size / subject.size) * 100;
    if (percentDiff > 100) {
      return Math.round(percentDiff - 100) + '% larger';
    } else {
      return Math.round(100 - percentDiff) + '% smaller';
    }
  };

  return `
    <div>
        <div
          style="font-size: 13px;
            color: ${isPartialMatch ? '#333b2b' : theme.colors.success};
            font-weight: 500;"
        >
          <img
            style="max-height: 15px; margin-right: 2px"
            src=${
              isPartialMatch ? '/present/assets/images/map/pin-gray-a.svg' : '/present/assets/images/map/pin-a.svg'
            }
            alt=''
          />
          ${property.address.deliveryLine}
        </div>
        <hr />
        <div style="margin-top: 5px; margin-bottom: 5px; font-weight: bold;">
          List price: ${MoneyFormatter.format(property.price)}
          <br />
          List date: ${property.listDateHuman} <br />
          ${property.beds} Beds <br />
          ${
            !isCanadaUser
              ? `${NumberFormatter.format(property.size)} sqft (${calculatePercentDiff()})`
              : ''
          }
        </div>
      </div>
  `;
};
