import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import Loader from '../../components/Loader/Loader';
import { corePresentApi } from '../../api/CorePresentApi';
import { operations } from '../PresentationCreate/dash/store/search';
import { URLS } from '../PresentationCreate/dash/view/constants/URLS';
import { PRESENTATION_TYPES } from '../../constants/PresentationTypes';
import * as reportActions from '../PresentationCreate/dash/store/report/actions';
import { PropertyCommunicator } from '../PresentationCreate/dash/communicators/Property/PropertyCommunicator';
import { Timestamp } from '../../services/Timestamp';

interface IAddress {
  address?: string | null;
  number?: string | null;
  city?: string | null;
  state?: string | null;
}

export const KvCoreReport: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const setAddress = useCallback(
    (address: IAddress) => {
      dispatch(operations.setAddress(address));
    },
    [dispatch],
  );

  const setSearchCriteria = useCallback(
    (searchCriteria: any) => {
      dispatch(operations.setSearchCriteria(searchCriteria));
    },
    [dispatch],
  );

  const resetSearch = () => {
    dispatch(operations.resetSearch());
  };

  const setRebuildData = useCallback(
    (data: any) => {
      dispatch(reportActions.setRebuildData(data));
    },
    [dispatch],
  );

  const setClients = useCallback(
    (clients: any) => {
      dispatch(operations.setClients(clients));
    },
    [dispatch],
  );

  const startBuildingReport = useCallback(
    async (address: IAddress, property: any) => {
      const searchCriteria = {
        id: property.id,
        propertyType: property.propertyType,
        status: property.saleType,
        beds: property.beds,
        baths: property.baths,
        size: property.size,
        totalSize: property.totalSize,
        yearBuilt: property.yearBuilt,
        coordinates: property.coordinates,
        totalSizeSet: false,
        reportCreatedAt: Timestamp.unix(),
      };

      setAddress(address);
      setSearchCriteria(searchCriteria);
      setRebuildData({});
    },
    [setAddress, setRebuildData, setSearchCriteria],
  );

  const init = useCallback(async () => {
    setLoading(true);
    const url = new URL(window.location.href.replace('#', '%23'));
    const address = url.searchParams.get('address')!.replace('#', '%23');
    const number = url.searchParams.get('number')!;
    const city = url.searchParams.get('city')!;
    const state = url.searchParams.get('state')!;
    const client = url.searchParams.get('client_id');

    if (!address || !city || !state) {
      setLoading(false);
      const path = `?address=${address}&number=${number}&city=${city}&state=${state}&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`;

      toast(
        'The address information gathered from kvCORE is not complete. Please fill in the details.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      push(URLS.SEARCH.ADDRESS + path);
      return;
    }
    if (!client) {
      const path = `?address=${address}&number=${number}&city=${city}&state=${state}&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`;
      push(URLS.SEARCH.ADDRESS + path);
      return;
    }

    try {
      const searchCriteria = await PropertyCommunicator.search({
        deliveryLine: address,
        number,
        city,
        state,
      });
      if (!searchCriteria.beds) {
        const singleClient = await corePresentApi.post('/clients/get-single', {
          id: client,
        });
        setClients(singleClient);
        toast(
          'The address information gathered from public record is not complete. Please fill in the details.',
          {
            type: 'error',
            position: 'bottom-center',
          },
        );
        const path = `?address=${address}&number=${number}&city=${city}&state=${state}&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`;
        push(URLS.SEARCH.ADDRESS + path);
        return;
      }
      await startBuildingReport({ address, number, city, state }, searchCriteria);

      try {
        const singleClient = await corePresentApi.post('/clients/get-single', {
          id: client,
        });
        setClients(singleClient);
      } catch (e: any) {
        console.error(e);
      }
      push(URLS.SEARCH.DETAILS + `?type=${PRESENTATION_TYPES.WIN_THE_LISTING}`);
    } catch (e: any) {
      const path = `?nonExistent=1&address=${address}&number=${number}&city=${city}&state=${state}&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`;
      push(URLS.SEARCH.ADDRESS + path);
    } finally {
      setLoading(false);
    }
  }, [push, setClients, startBuildingReport]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div
      style={{
        height: '100vh',
        width: '100wv',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader size='3x' centered />
    </div>
  );
};
