import { useMemo } from 'react';
import { TProperty } from '../../../../../../../types';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { useDaysOnMarket } from '../../../../../../../hooks/daysOnMarket/useDaysOnMarket';
import { useAtomValue } from 'jotai';
import { searchCriteriaAtom } from '../../../../../state/searchCriteriaAtom';
import styled from 'styled-components';
import { isPresentationViewAtom } from '../../../../../state/isPresentationViewAtom';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';

interface Props {
  property: TProperty;
}

export const Details = ({ property }: Props) => {
  const { isCanadaUser } = useUser();
  const DOM = useDaysOnMarket(property);

  const searchCriteria = useAtomValue(searchCriteriaAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const { deliveryLine, city, state, zip } = property.address;
  const sizeDiffString = useMemo(() => {
    return parseInt(String(property?.size)) - parseInt(String(searchCriteria?.size)) > 0
      ? `${parseInt(String(property?.size)) - parseInt(String(searchCriteria?.size))} sqft larger`
      : `${parseInt(String(searchCriteria?.size)) - parseInt(String(property?.size))} sqft smaller`;
  }, [property?.size, searchCriteria?.size]);

  if (!property?.address?.deliveryLine) return null;

  return (
    <DetailsContainer isPresentationView={isPresentationView} isExcluded={property?.excluded}>
      <Title>
        <AddressDeliveryLine>{deliveryLine.toLowerCase()}</AddressDeliveryLine>
        <br />
        {city.toLowerCase()}, {state} {zip}
      </Title>

      <Market>
        <span>Beds:</span> {property?.beds ?? 'n/a'}{' '}
        {property.status !== 'pending' ? `| ${DOM.label}: ${DOM.value ?? 'n/a'}` : ''} |{' '}
        {isCanadaUser ? `${property?.distanceKm} km away` : `${property?.distance} mi away`}
      </Market>
      {property.size && searchCriteria?.size ? (
        <Size>
          {NumberFormatter.format(property.size) || 'n/a'} sqft ({sizeDiffString})
        </Size>
      ) : (
        ''
      )}
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div<{ isPresentationView?: boolean; isExcluded?: boolean }>`
  text-transform: none;
  padding: ${({ isPresentationView }) => (isPresentationView ? '0 15px' : '0 5px 0 15px')};
  flex-grow: 1;
  filter: ${({ isExcluded }) => (isExcluded ? 'grayscale(1)' : 'none')};
  opacity: ${({ isExcluded }) => (isExcluded ? 0.5 : 1)};
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.textLight};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.gray[400]};
  overflow: hidden;
`;

const AddressDeliveryLine = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const Size = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};

  span {
    font-weight: bold;
  }
`;

const Market = styled.p`
  color: ${({ theme }) => theme.colors.gray[400]};
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
`;
