import { useTheme } from 'styled-components';
import { getWhiteLabelColors } from '../../../../../features/whitelabel/getWhiteLabelColors';
import { usePresentation } from '../../../../../hooks/usePresentation';
import { TPresentation } from '../../../../../types';
import { useSlideProps } from '../../../providers/SlideProvider';
import { useWhiteLabelSettings } from '../../../../../features/whitelabel/useWhiteLabelSettings';
import { useMemo } from 'react';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import logoCP from './assets/logo_web.png';
import logoBT from './assets/logo_web_bt.svg';
import socialLogo from './socialLogo';

export const useSlideLogo = () => {
  const theme = useTheme();
  const { whiteLabelSettings } = useSlideProps();
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const hasWL = Boolean(whiteLabelSettings);

  const whiteLabelLogoColors = useMemo(() => {
    if (!hasWL) return null;

    const { primaryLogoColor, secondaryLogoColor } = getWhiteLabelColors({
      theme,
      hasWL: Boolean(whiteLabelSettings),
      wlPrimaryColor: whiteLabelSettings?.primaryColor,
      wlSecondaryColor: whiteLabelSettings?.secondaryColor,
      packageLevel: whiteLabelSettings?.packageLevel,
    });
    return {
      primaryLogoColor,
      secondaryLogoColor,
    };
  }, [hasWL, theme, whiteLabelSettings]);

  const logoWL = useMemo(() => {
    if (!whiteLabelLogoColors) return null;
    return svgStringToDataUrl(
      socialLogo,
      whiteLabelLogoColors.primaryLogoColor,
      whiteLabelLogoColors.secondaryLogoColor,
    );
  }, [whiteLabelLogoColors]);

  return useMemo(() => {
    if (!hasWL) {
      const logoBTorCP = hasBtTheme ? logoBT : logoCP;
      return logoBTorCP;
    }

    return logoWL;
  }, [hasBtTheme, hasWL, logoWL]);
};
