import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyTypeText } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyTypeText';
import { TooltipPayloadProps } from '../../../../types';
import { Adjusted, PriceWrapper, TooltipWrapper } from '../components/UI';

interface PricePerSQFTTooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
}

export const PricePerSqftTooltip = ({ active, payload }: PricePerSQFTTooltipProps) => {
  if (!active) {
    return null;
  }

  const price = payload?.[0]?.payload.price;
  const label = payload?.[0]?.payload?.address?.deliveryLine;
  const adjustedPrice = payload?.[0]?.payload?.adjustedPrice;

  const isSold = payload?.[0]?.payload?.status === 'sold';

  const text = isSold ? 'Sale Price' : 'Price';

  return (
    <TooltipWrapper>
      <p>{label}</p>
      <p>Status: {getPropertyTypeText(payload?.[0]?.payload?.status)}</p>

      <PriceWrapper>
        <p
          style={{
            color: payload?.[0]?.color,
          }}
        >{`${text}: ${MoneyFormatter.format(price)}`}</p>
        {Boolean(adjustedPrice) && (
          <Adjusted>
            ADJ {text}: {MoneyFormatter.format(adjustedPrice)}
          </Adjusted>
        )}
      </PriceWrapper>

      <p
        style={{
          color: payload?.[0]?.color,
        }}
      >{`SQFT: ${NumberFormatter.format(payload?.[0]?.payload?.size)}`}</p>
      <p
        style={{
          color: payload?.[0]?.color,
        }}
      >{`Price/SQFT: ${MoneyFormatter.format(payload?.[0]?.payload?.pricePerSqft)}`}</p>
    </TooltipWrapper>
  );
};
