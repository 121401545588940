import { getPropertyId } from '../../../../../../../../features/report/services/getPropertyId';

export const extractHighlightedProperties = (properties, highlighted) => {
  const { active, pending, sold, comingSoon } = properties;

  return [...(active ?? []), ...(pending ?? []), ...(sold ?? []), ...(comingSoon ?? [])]
    .filter(property =>
      highlighted.find(
        highlightedProperty => getPropertyId(highlightedProperty) === getPropertyId(property),
      ),
    )
    .sort((a, b) => (a.sold_price || a.price) - (b.sold_price || b.price));
};
