import React from 'react';
import { Handle } from 'rc-slider';

import classes from './PercentageRange.module.scss';
import { Range } from '../../../../../../../../components/Range/Range';
import { NumberFormatter } from '../../../../../../../../../services/numberFormatter';
import { ReactComponent as HomeIcon } from './home.svg';

const calculateOffset = characters => {
  if (characters === 4) {
    return -11;
  }

  if (characters === 3) {
    return -7;
  }

  if (characters === 2) {
    return -3;
  }

  if (characters === 1) {
    return 0;
  }

  return -15;
};

const myHandle =
  step =>
  ({ value, dragging, index, ...restProps }) => {
    const percentage = `${-1 * (100 - (value / step) * 5)}%`;

    const topLeftOffset = calculateOffset(percentage.length);
    const bottomLeftOffset = calculateOffset(`${value}`.length);

    return (
      <Handle {...restProps} key={index}>
        <div className={classes.topValue} style={{ left: `${topLeftOffset}px` }}>
          {percentage}
        </div>
        <div className={classes.bottomValue} style={{ left: `${bottomLeftOffset}px` }}>
          {NumberFormatter.format(value)}
        </div>
      </Handle>
    );
  };

export const PercentageRange = ({
  changed = f => f,
  options = [],
  value = [],
  size = 1,
  className = '',
}) => {
  const step = Math.round(size / 20);
  const handle = myHandle(step);
  return (
    <div className={`${classes.rangeTopMargin} ${className}`}>
      <div className={classes.between}>
        <span className={classes.topLeft}>-100%</span>
        <span className={classes.topRight}>100%</span>
      </div>

      <div className={classes.rangeWrapper}>
        <Range
          allowCross={false}
          changed={changed}
          marks={options}
          initValue={value}
          customHandle={handle}
          step={step}
        />
        <div className={classes.centerIcon}>
          <HomeIcon />
        </div>
      </div>

      <div className={classes.between}>
        <span className={classes.bottomLeft}>0</span>
        <span className={classes.bottomCenter}>{NumberFormatter.format(size)}</span>
        <span className={classes.bottomRight}>{NumberFormatter.format(step * 40)}</span>
      </div>
    </div>
  );
};
