import React, { useCallback, useMemo } from 'react';

import { TEmphasisConfig, TEmphasisMeta } from '../types';
import { staticEmphasisConfigs } from '../services/staticEmphasisConfigs';
import { stringifySlidesOrdering } from '../services/stringifySlidesOrdering';
import {
  TEmphasis,
  TSection,
  TSlideId,
  TSlide,
  TUserConfigsCategory,
  InheritanceLevel,
  TDynamicOrder,
  TFullOrder,
  EntityMode,
} from '../../../../../../../types';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { PresentationConfigsFactory } from '../../../../../../../pages/PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';

import { useUserCategoryCustomizations } from '../../../../../../../hooks/useUserCategoryCustomizations';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../../providers/providers/PresentationModeProvider';
import { usePresentationMeta } from '../../../../../../../providers/providers/PresentationMetaProvider';
import { OrderValidator } from '../../../../../../../services/validators/OrderValidator';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';

// convert ordering of sectionSlides from customization order
export const convertCustomizationSectionSlides = (
  invalidSectionsSlide: Record<string, Partial<TSlide>[]>,
) => {
  const validSections: Record<string, TSlideId[]> = {};

  for (const key in invalidSectionsSlide) {
    validSections[key] = invalidSectionsSlide[key]
      .filter(s => s.includeSlide)
      .map(item => item.id! as TSlideId);
  }
  return validSections;
};

function useDefaultOrderConfig(): { isLoading: boolean; config: TEmphasisMeta } {
  const { hash, primaryEntity, useSpecialBranding } = useUser()!;
  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const { slides } = useSlides();

  const defaultOrder = PresentationConfigsFactory.create(
    presentationType,
    presentationMode,
  ).getDefaultOrder();

  const validator = useMemo(
    () => new OrderValidator(presentationType, presentationMode),
    [presentationType, presentationMode],
  );

  const {
    data: agentCustomizations,
    isLoading,
    isFetching,
  } = useUserCategoryCustomizations(hash, null, useSpecialBranding);

  const { data: officeCustomizations } = useUserCategoryCustomizations(
    hash,
    primaryEntity,
    useSpecialBranding,
  );

  const { data: accountCustomizations } = useUserCategoryCustomizations(
    hash,
    0,
    useSpecialBranding,
  );

  const { data: superaccountCustomizations } = useUserCategoryCustomizations(
    hash,
    useSpecialBranding ? -2 : -1,
    useSpecialBranding,
  );

  const stringifyDefaultSlidesOrdering = useCallback(
    (order?: TFullOrder) => {
      if (isLoading || isFetching || !Object.keys(slides ?? {}).length) return '';

      const validCustomizationSlides = order?.sectionsSlide;

      const defaultSectionsOrder = order?.sections ?? defaultOrder.sections;

      const defaultSectionsSlideOrder = validCustomizationSlides
        ? convertCustomizationSectionSlides(validCustomizationSlides!)
        : defaultOrder.sectionsSlide;

      if (!defaultSectionsOrder || !Object.keys(defaultSectionsSlideOrder).length) return '';

      const sectionsSlide = validator.getPartialSectionsSlidesOrder(
        defaultSectionsSlideOrder,
        slides ?? {},
      );

      const defaultSlidesOrderingStr = stringifySlidesOrdering({
        sections: defaultSectionsOrder,
        sectionsSlide,
      });

      return defaultSlidesOrderingStr;
    },
    [defaultOrder.sections, defaultOrder.sectionsSlide, isFetching, isLoading, slides, validator],
  );

  const { inheritanceLevel } = usePresentationMeta();

  const order = useMemo(() => {
    if (isLoading) return [];

    if (inheritanceLevel === InheritanceLevel.SUPERACCOUNT) {
      return (superaccountCustomizations?.orderByType?.[presentationType]?.sections ??
        defaultOrder.sections) as TSection[];
    }

    if (inheritanceLevel === InheritanceLevel.ACCOUNT) {
      return (accountCustomizations?.orderByType?.[presentationType]?.sections ??
        defaultOrder.sections) as TSection[];
    }

    if (inheritanceLevel === InheritanceLevel.OFFICE) {
      return (officeCustomizations?.orderByType?.[presentationType]?.sections ??
        defaultOrder.sections) as TSection[];
    }

    return (agentCustomizations?.orderByType?.[presentationType]?.sections ??
      defaultOrder.sections) as TSection[];
  }, [
    isLoading,
    inheritanceLevel,
    agentCustomizations?.orderByType,
    presentationType,
    defaultOrder.sections,
    superaccountCustomizations?.orderByType,
    accountCustomizations?.orderByType,
    officeCustomizations?.orderByType,
  ]);

  const slidesOrder = useMemo(() => {
    if (isLoading) return '';

    const customizations = {
      [InheritanceLevel.AGENT]: agentCustomizations,
      [InheritanceLevel.OFFICE]: officeCustomizations,
      [InheritanceLevel.ACCOUNT]: accountCustomizations,
      [InheritanceLevel.SUPERACCOUNT]: superaccountCustomizations,
    };

    if (!inheritanceLevel) return '';

    const order =
      customizations[inheritanceLevel]?.orderByType?.[presentationType] ??
      customizations[inheritanceLevel]?.order;

    return stringifyDefaultSlidesOrdering(order);
  }, [
    isLoading,
    agentCustomizations,
    officeCustomizations,
    accountCustomizations,
    superaccountCustomizations,
    inheritanceLevel,
    presentationType,
    stringifyDefaultSlidesOrdering,
  ]);

  const levelLabel = useMemo(() => {
    const labels = {
      [InheritanceLevel.SUPERACCOUNT]: 'Brand',
      [InheritanceLevel.ACCOUNT]: 'Company Default',
      [InheritanceLevel.OFFICE]: 'Office/Team Default',
      [InheritanceLevel.AGENT]: 'Agent Default',
    };

    return labels[inheritanceLevel] ?? '';
  }, [inheritanceLevel]);

  return React.useMemo(
    () => ({
      isLoading: isLoading || isFetching,
      config: {
        value: 'defaultOrder',
        label: 'Default Order',
        levelLabel,
        order,
        slidesOrder,
        level: inheritanceLevel,
      },
    }),
    [isLoading, isFetching, order, slidesOrder, levelLabel, inheritanceLevel],
  );
}

export function useStaticConfigs(): TEmphasisMeta[] {
  return Object.values(staticEmphasisConfigs).filter(c => c.value !== 'defaultOrder');
}

export function useEmphasisConfigs(): TEmphasisConfig | null {
  const { isLoading, config: defaultOrderConfig } = useDefaultOrderConfig();
  const staticConfigs = useStaticConfigs();

  return React.useMemo(() => {
    if (isLoading) return null;

    const emphasisConfigsMap = [defaultOrderConfig, ...staticConfigs].reduce(
      (combined, emphasisConfig) => ({
        ...combined,
        [emphasisConfig.value]: { ...emphasisConfig },
      }),
      {},
    ) as Record<TEmphasis, TEmphasisMeta>;

    const orderById: TEmphasis[] = [
      'defaultOrder',
      'rightTimeToSell',
      'agentIsTheRightFit',
      'topDollarValue',
    ];

    return {
      orderById,
      configs: emphasisConfigsMap,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, defaultOrderConfig?.slidesOrder]);
}
